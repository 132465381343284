import React, { useContext, useEffect, useMemo } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { StatusContext } from "../../contexts/status-context";

const Layout = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && !isPassed.isLogged) {
            keycloak.loadUserProfile().then((userProfile) => {
                if (userProfile?.attributes?.businessId) {
                    if(keycloak.hasResourceRole('manager')) {
                        setContext({ isLogged: true, businessId: userProfile.attributes?.businessId[0], others: {}});
                    } else if(keycloak.hasResourceRole('seller')) {
                        setContext({ isLogged: true, businessId: userProfile.attributes?.businessId[0], others: {
                            sellerId: userProfile.attributes?.sellerId[0]
                        }});
                    }
                }
            });
        }
    }, [context, initialized]);

    return (
        <>
            <Navbar collapseOnSelect bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#"><strong className="text-success">Negrdo</strong> <sub className="text-muted" style={{ fontSize: '10px' }}>Distributions</sub></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        {keycloak.authenticated && (
                            <>
                                <Nav className="me-auto" navbarScroll>
                                    <>
                                        <Nav.Link as={Link} to={'/'} href="#" className="nav-link"><i className="bi bi-grid-1x2 text-success"></i> Overview</Nav.Link>
                                        <Nav.Link as={Link} to={'/customers'} href="#" className="nav-link"><i className="bi bi-person-vcard text-success"></i> Clientes</Nav.Link>
                                        <Nav.Link as={Link} to={'/products'} href="#" className="nav-link"><i className="bi bi-boxes text-success"></i> Productos</Nav.Link>
                                        <Nav.Link as={Link} to={'/sales'} href="#" className="nav-link"><i className="bi bi-cart3 text-success" /> Ventas</Nav.Link>
                                        {keycloak.hasResourceRole('seller') && (
                                            <Nav.Link as={Link} to={'/assigned'} href="#" className="nav-link"><i className="bi bi-sign-intersection-y text-success" /> Mis Rutas</Nav.Link>
                                        )}
                                    </>
                                </Nav>

                                <Nav navbarScroll>
                                    {keycloak.hasResourceRole('manager') && (
                                        <>
                                            <NavDropdown title={<span><i className="bi bi-clipboard2-data text-success" /> Consolidados</span>} id="basic-nav-dropdown-consolidations">
                                                <NavDropdown.Item as={Link} href="#" to={'/consolidations/general'}><i className="bi bi-sign-intersection-y text-success"></i> General</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/consolidations/customers'}><i className="bi bi-signpost-split-fill text-success"></i> Tiendas</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/consolidations/sellers'}><i className="bi bi-person-badge text-success"></i> Vendedores</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/consolidations/suppliers'}><i className="bi bi-cash-stack text-success"></i> Proveedores</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/consolidations/business'}><i className="bi bi-people-fill text-success"></i> Empresa</NavDropdown.Item>
                                            </NavDropdown>
                                            <NavDropdown title={<span><i className="bi bi-gear text-success" /> Configuraciones</span>} id="basic-nav-dropdown-settings">
                                                <NavDropdown.Item as={Link} href="#" to={'/routes'}><i className="bi bi-sign-intersection-y text-success"></i> Rutas</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/assignments'}><i className="bi bi-signpost-split-fill text-success"></i> Asignación de rutas</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/sellers'}><i className="bi bi-person-badge text-success"></i> Vendedores</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/assorts'}><i className="bi bi-cash-stack text-success"></i> Cuadres</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item as={Link} href="#" to={'/suppliers'}><i className="bi bi-people-fill text-success"></i> Proveedores</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/deliverers'}><i className="bi bi-file-person text-success"></i> Repartidores</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/packers'}><i className="bi bi-file-person-fill text-success"></i> Empacadores</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/users'}><i className="bi bi-shield-lock text-success"></i> Usuarios</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item as={Link} href="#" to={'/account'}><i className="bi bi-person-badge-fill text-success"></i> Cuenta</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} href="#" to={'/payments'}><i className="bi bi-sign-intersection-y text-success"></i> Payments</NavDropdown.Item>
                                            </NavDropdown>
                                        </>
                                    )}
                                    <NavDropdown title={<span><i className="bi bi-person-circle text-success" /> {keycloak?.profile?.firstName}</span>} id="basic-nav-dropdown-settings">
                                        
                                        <NavDropdown.Item onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}` })}><i className="bi bi-x-lg text-success"></i> Cerrar sesión</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </>

                        )}

                        {/* <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="outline-success">Search</Button>
                        </Form> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <main className="mt-3">
                <Outlet />
            </main>
        </>
    );

}
export default Layout;