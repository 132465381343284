import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Placeholder, Row } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { apiGallery, apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';

const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
`;

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Products = () => {

  const { keycloak, initialized } = useKeycloak();
  const [context, setContext] = useContext(StatusContext);

  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [products, setProducts] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const [createCategory, setCreateCategory] = useState({});
  const [category, setCategory] = useState(false);
  const handleCloseCreateCategory = () => setCategory(false);
  const handleShowCreateCategory = () => setCategory(true);
  const [creatingCategory, setCreatingCategory] = useState(false);

  const [createUnit, setCreateUnit] = useState({});
  const [unit, setUnit] = useState(false);
  const handleCloseCreateUnit = () => setUnit(false);
  const handleShowCreateUnit = () => setUnit(true);
  const [creatingUnit, setCreatingUnit] = useState(false);

  const [createProduct, setCreateProduct] = useState({});
  const [create, setCreate] = useState(false);
  const handleCloseCreateProduct = () => setCreate(false);
  const handleShowCreateProduct = () => setCreate(true);
  const [creatingProduct, setCreatingProduct] = useState(false);

  const [updateProduct, setUpdateProduct] = useState({});
  const [update, setUpdate] = useState(false);
  const handleCloseUpdate = () => setUpdate(false);
  const handleShowUpdate = () => setUpdate(true);
  const [updatingProduct, setUpdatingProduct] = useState(false);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = products.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    || item.unit?.name && item.unit?.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const columns = [
    {
      name: 'Codigo',
      selector: row => row.code,
      sortable: true,
    },
    {
      name: 'Foto',
      selector: row => <><img src={`${apiGallery()}/images/${row?.business?.logo}`} alt={'logo'} style={{ width: '40px', height: '40px' }} /></>,
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Unidad',
      selector: row => row.unit?.name,
      sortable: true,
    },
    {
      name: 'Proveedor',
      selector: row => row.supplier?.name,
      sortable: true,
    },
    {
      name: 'Precio Compra',
      selector: row => <>{row.purchasePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>,
      sortable: true,
    },
    {
      name: 'Porcentaje',
      selector: row => <><span className="badge rounded-pill bg-primary">{row.percentage}%</span></>,
      sortable: true,
    },
    {
      name: 'Precio Porcentaje',
      selector: row => <>{row.percentagePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>,
      sortable: true,
    },
    {
      name: 'Precio Venta',
      selector: row => <>{row.salePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>,
      sortable: true,
    },
    {
      name: 'Estado',
      selector: row => <>{row.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</>,
      sortable: true,
    },
    {
      name: 'Acciones',
      selector: row => <><Button title="Ver detalles" className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>{' '}<Button onClick={() => getProduct(row.id)} title="Editar datos de cliente" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button></>,
      sortable: false,
    },
  ];

  const isPassed = useMemo(() => {
    return context;
  }, [context]);

  useEffect(() => {
    if (initialized && isPassed.businessId) {
      getProducts();
      getCategories();
      getUnits();
      getSuppliers();
    }
  }, [context, initialized, isPassed.businessId]);

  const getProducts = async () => {
    setLoaded(false);
    await axios.get(`${apiRequest()}/products/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setProducts(res.data);
      setLoaded(true);
    }).catch((err) => {
      setLoaded(true);
      toast.error({ render: "Error: No se pudo obtener los productos, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const getSuppliers = async () => {
    await axios.get(`${apiRequest()}/suppliers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      const serverSuppliers = res.data;
      const transformed = serverSuppliers.map((supplier) => ({
        value: supplier.id,
        label: supplier.name + ' | ' + supplier.phone,
        key: supplier.id
      }));
      const withDefault = [
        { value: '', label: 'Selecciona un Proveedor', key: 0 },
        ...transformed
      ];
      setSuppliers(withDefault);
    }).catch((err) => {
      console.error('error', err);
    });
  }

  const getCategories = async () => {
    await axios.get(`${apiRequest()}/categories/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setCategories(res.data);
    }).catch((err) => {
      console.error('error', err);
    });
  }

  const getUnits = async () => {
    await axios.get(`${apiRequest()}/units/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setUnits(res.data);
    }).catch((err) => {
      console.error('error', err);
    });
  }

  const getProduct = async (id) => {
    setUpdateProduct({});
    handleShowUpdate();
    await axios.get(`${apiRequest()}/products/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setUpdateProduct(res.data);
    }).catch((err) => {
      console.error('error', err);
    });
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    setUpdatingProduct(true);
    await axios.put(`${apiRequest()}/products/${updateProduct.id}`, updateProduct, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setUpdateProduct({});
        handleCloseUpdate();
        toast.update(toastId, { render: "Operación realizada con exito", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        setUpdatingProduct(false);
        getProducts();
      }
    }).catch((_err) => {
      setUpdatingProduct(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveProduct = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    setCreatingProduct(true);
    await axios.post(`${apiRequest()}/products/business/${isPassed.businessId}`, createProduct, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setCreateProduct({});
        handleCloseCreateProduct();
        toast.update(toastId, { render: "Operación realizada con exito", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        setCreatingProduct(false);
        getProducts();
      }
    }).catch((_err) => {
      setCreatingProduct(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveCategory = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    setCreatingCategory(true);
    await axios.post(`${apiRequest()}/categories/business/${isPassed.businessId}`, createCategory, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setCreateCategory({});
        handleCloseCreateCategory();
        toast.update(toastId, { render: "Operación realizada con exito", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        setCreatingCategory(false);
        getCategories();
      }
    }).catch((_err) => {
      setCreatingCategory(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveUnit = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    setCreatingUnit(true);
    await axios.post(`${apiRequest()}/units/business/${isPassed.businessId}`, createUnit, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setCreateUnit({});
        handleCloseCreateUnit();
        toast.update(toastId, { render: "Operación realizada con exito", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        setCreatingUnit(false);
        getUnits();
      }
      setCreatingUnit(false);
    }).catch((_err) => {
      setCreatingUnit(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setCreateProduct({ ...createProduct, name: newName, description: newName });
  };

  const calculateSalePrice = () => {
    const purchasePrice = parseFloat(createProduct.purchasePrice);
    const percentage = parseFloat(createProduct.percentage);
    const percentagePrice = parseFloat(purchasePrice + (purchasePrice * (percentage / 100)));
    const salePrice = parseFloat(percentagePrice);
    setCreateProduct({ ...createProduct, percentagePrice, salePrice });
  };

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField
        id="search"
        type="text"
        placeholder="Buscar por Nombre"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        autoFocus={true}
      />
      <ClearButton type="button" onClick={onClear}>
        X
      </ClearButton>
    </>
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container className='mt-5'>
      <Row>
        <Col xs={12}>
          <div className="page-title">
            <h3 className='text-center'>Mis productos</h3>
            <p className="text-center text-muted">
              ¡Bienvenido a nuestra plataforma de productos! En esta sección, podrás explorar la lista completa de productos asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de productos.
              <br />¡Que disfrutes explorando la lista de productos!
              <br />
              {(keycloak.hasResourceRole('manager') || keycloak.hasResourceRole('secretary')) && (
                <>
                  <Button variant="outline-success" size="sm" onClick={handleShowCreateCategory}>Nueva categoria</Button>{' '}
                  <Button variant="success" size="sm" onClick={handleShowCreateProduct}>Nuevo producto</Button>{' '}
                  <Button variant="outline-dark" size="sm" onClick={handleShowCreateUnit}>Nueva unidad</Button>
                </>
              )}
            </p>
          </div>
          <Card>
            <Card.Body>
              {(products.length === 0 && !loaded) ? (
                <>
                  <Placeholder as={Row} size="xs" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} size="sm" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} size="lg" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                </>
              ) :
                <>
                  <DataTable
                    //title="Listado de productos"
                    columns={columns}
                    data={filteredItems}
                    progressPending={!loaded}
                    pagination={true}
                    paginationResetDefaultPage={resetPaginationToggle}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                </>
              }

            </Card.Body>
          </Card>
          <Offcanvas placement="end" show={unit} onHide={handleCloseCreateUnit} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Nueva unidad</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              ¡Bienvenido al proceso de registro de unidades!
              <br />
              El registro de unidades es el primer paso para aprovechar al máximo todas las funcionalidades que ofrecemos. Por favor, completa el formulario a continuación con la información necesaria para crear tu cuenta y comenzar a gestionar tus productos de manera eficiente. Recuerda proporcionar datos precisos y actualizados para garantizar una experiencia óptima. Una vez que completes el registro, podrás acceder a tu cuenta y empezar a explorar todas las características diseñadas para simplificar la gestión de unidades.
              <br />
              <br />
              <Form onSubmit={saveUnit}>
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createUnit.name} onChange={(e) => setCreateUnit({ ...createUnit, name: e.target.value })} required />
                <Form.Label>Abreviación</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createUnit.abbreviation} onChange={(e) => setCreateUnit({ ...createUnit, abbreviation: e.target.value })} required />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={creatingUnit}>Guardar</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseCreateUnit}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas placement="end" show={category} onHide={handleCloseCreateUnit} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Nueva categoria</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              ¡Bienvenido al proceso de registro de categorias!
              <br />
              El registro de categorias es el primer paso para aprovechar al máximo todas las funcionalidades que ofrecemos. Por favor, completa el formulario a continuación con la información necesaria para crear tu cuenta y comenzar a gestionar tus productos de manera eficiente. Recuerda proporcionar datos precisos y actualizados para garantizar una experiencia óptima. Una vez que completes el registro, podrás acceder a tu cuenta y empezar a explorar todas las características diseñadas para simplificar la gestión de categorias.
              <br />
              <br />
              <Form onSubmit={saveCategory}>
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createCategory.name} onChange={(e) => setCreateCategory({ ...createCategory, name: e.target.value })} required />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={creatingCategory}>Guardar</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseCreateCategory}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas placement="end" show={create} onHide={handleCloseCreateProduct} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Nuevo producto</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              ¡Bienvenido al proceso de registro de productos!
              <br />
              El registro de productos es el primer paso para aprovechar al máximo todas las funcionalidades que ofrecemos. Por favor, completa el formulario a continuación con la información necesaria para crear tu cuenta y comenzar a gestionar tus productos de manera eficiente. Recuerda proporcionar datos precisos y actualizados para garantizar una experiencia óptima. Una vez que completes el registro, podrás acceder a tu cuenta y empezar a explorar todas las características diseñadas para simplificar la gestión de productos.
              <br />
              <br />
              <Form onSubmit={saveProduct}>
                <Form.Label>Proveedor</Form.Label>
                <Select defaultValue={suppliers.find((supplier) => supplier.id === createProduct?.supplier?.id) || suppliers[0]} onChange={(e) => setCreateProduct({ ...createProduct, supplier: { id: e.value } })} classNamePrefix={'select'} options={suppliers} className="mb-3" isSearchable={true} isClearable={false} required />
                {/* <Form.Select className="mb-3" defaultValue={createProduct?.supplier?.id} onChange={(e) => setCreateProduct({ ...createProduct, supplier: { id: e.target.value } })} required>
                  <option value="">Selecciona un proveedor</option>
                  {suppliers.map((supplier, _index) => {
                    return (
                      <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                    )
                  })}
                </Form.Select> */}
                <Form.Label>Categoria</Form.Label>
                <Form.Select className="mb-3" defaultValue={createProduct?.category?.id} onChange={(e) => setCreateProduct({ ...createProduct, category: { id: e.target.value } })} required>
                  <option value="">Selecciona una categoria</option>
                  {categories.map((category, _index) => {
                    return (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    )
                  })}
                </Form.Select>
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" value={createProduct.name || ''} onChange={handleNameChange} required />
                <Form.Label>Descripción</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" value={createProduct.description || ''} onChange={(e) => setCreateProduct({ ...createProduct, description: e.target.value })} required />
                <Form.Label>Precio compra</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" value={createProduct.purchasePrice || 0} onChange={(e) => setCreateProduct({ ...createProduct, purchasePrice: e.target.value })} onKeyUp={calculateSalePrice} required />
                <Form.Label>Porcentaje</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" value={createProduct.percentage || 0} onChange={(e) => setCreateProduct({ ...createProduct, percentage: e.target.value })} onKeyUp={calculateSalePrice} required />
                <Form.Label>Precio Porcantaje</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" value={createProduct.percentagePrice || 0} onChange={(e) => setCreateProduct({ ...createProduct, percentagePrice: e.target.value })} readOnly required />
                <Form.Label>Precio venta</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" value={createProduct.salePrice || 0} onChange={(e) => setCreateProduct({ ...createProduct, salePrice: e.target.value })} required />
                <Form.Label>Cantidad</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={createProduct.stock} onChange={(e) => setCreateProduct({ ...createProduct, stock: e.target.value })} required />
                <Form.Label>Unidad</Form.Label>
                <Form.Select className="mb-3" defaultValue={createProduct?.unit?.id} onChange={(e) => setCreateProduct({ ...createProduct, unit: { id: e.target.value } })} required>
                  <option value="">Selecciona una unidad</option>
                  {units.map((unit, _index) => {
                    return (
                      <option key={unit.id} value={unit.id}>{unit.name}</option>
                    )
                  })}
                </Form.Select>
                <Form.Label>Estado</Form.Label>
                <Form.Switch checked={createProduct.status || false} value={createProduct.status} onChange={(e) => setCreateProduct({ ...createProduct, status: e.target.checked })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={creatingProduct}>Guardar</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseCreateProduct}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas placement="end" show={update} onHide={handleCloseUpdate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Actualizar producto</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              En este espacio, podrás realizar cambios y actualizaciones en la información de tus productos de manera rápida y sencilla. Mantener la información de tus clientes al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
              <br />
              <br />
              <Form onSubmit={saveChanges}>
                <Form.Label>Proveedor</Form.Label>
                <Select value={suppliers.find((supplier) => supplier.value === updateProduct.supplier?.id)} onChange={(e) => setUpdateProduct({ ...updateProduct, supplier: { id: e.value } })} classNamePrefix={'select'} options={suppliers} className="mb-3" isSearchable={true} isClearable={false} required />
                {/* <Form.Select className="mb-3" value={updateProduct?.supplier?.id} onChange={(e) => setUpdateProduct({ ...updateProduct, supplier: { id: e.target.value } })} required>
                  <option value="">Selecciona un proveedor</option>
                  {suppliers.map((supplier, _index) => {
                    return (
                      <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                    )
                  })}
                </Form.Select> */}
                <Form.Label>Categoria</Form.Label>
                <Form.Select className="mb-3" value={updateProduct?.category?.id} onChange={(e) => setUpdateProduct({ ...updateProduct, category: { id: e.target.value } })} required>
                  <option value="">Selecciona una categoria</option>
                  {categories.map((category, _index) => {
                    return (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    )
                  })}
                </Form.Select>
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateProduct.name} onChange={(e) => setUpdateProduct({ ...updateProduct, name: e.target.value })} required />
                <Form.Label>Descripción</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateProduct.description} onChange={(e) => setUpdateProduct({ ...updateProduct, description: e.target.value })} required />
                <Form.Label>Precio compra</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={updateProduct.purchasePrice} onChange={(e) => setUpdateProduct({ ...updateProduct, purchasePrice: e.target.value })} required />
                <Form.Label>Porcentaje</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={updateProduct.percentage} onChange={(e) => setUpdateProduct({ ...updateProduct, percentage: e.target.value })} required />
                <Form.Label>Precio Porcantaje</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={updateProduct.percentagePrice} onChange={(e) => setUpdateProduct({ ...updateProduct, percentagePrice: e.target.value })} required />
                <Form.Label>Precio venta</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={updateProduct.salePrice} onChange={(e) => setUpdateProduct({ ...updateProduct, salePrice: e.target.value })} required />
                <Form.Label>Cantidad</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={updateProduct.stock} onChange={(e) => setUpdateProduct({ ...updateProduct, stock: e.target.value })} required />
                <Form.Label>Unidad</Form.Label>
                <Form.Select className="mb-3" value={updateProduct?.unit?.id} onChange={(e) => setUpdateProduct({ ...updateProduct, unit: { id: e.target.value } })} required>
                  <option value="">Selecciona una unidad</option>
                  {units.map((unit, _index) => {
                    return (
                      <option key={unit.id} value={unit.id}>{unit.name}</option>
                    )
                  })}
                </Form.Select>
                <Form.Label>Estado</Form.Label>
                <Form.Switch defaultChecked={updateProduct.status} onChange={(e) => setUpdateProduct({ ...updateProduct, status: e.target.checked })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={updatingProduct}>Guardar</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseUpdate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <ToastContainer />
        </Col>
      </Row>
    </Container>
  );
}

export default Products;
