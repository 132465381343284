import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Placeholder, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';

const Users = () => {

  const { keycloak, initialized } = useKeycloak();
  const [context, setContext] = useContext(StatusContext);

  const [users, setUsers] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const [createInternalAccount, setCreateInternalAccount] = useState({});
  const [create, setCreate] = useState(false);
  const handleCloseCreate = () => setCreate(false);
  const handleShowCreate = () => setCreate(true);
  const [creating, setCreating] = useState(false);

  const [updateUser, setUpdateUser] = useState({});
  const [update, setUpdate] = useState(false);
  const handleCloseUpdate = () => setUpdate(false);
  const handleShowUpdate = () => setUpdate(true);
  const [updating, setUpdating] = useState(false);

  const isPassed = useMemo(() => {
    return context;
  }, [context]);

  useEffect(() => {
    if (initialized && isPassed.businessId) {
      getUsers();
    }
  }, [context, initialized, isPassed.businessId]);

  const getUsers = async () => {
    setLoaded(false);
    await axios.get(`${apiRequest()}/users/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setUsers(res.data);
      setLoaded(true);
    }).catch((_err) => {
      setLoaded(true);
      toast.error({ render: "Error: No se pudo obtener los usuarios, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const getUser = async (id) => {
    handleShowUpdate();
    setUpdateUser({});
    setUpdating(true);
    await axios.get(`${apiRequest()}/users/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setUpdateUser(res.data);
      setUpdating(false);
    }).catch((_err) => {
      setUpdating(false);
      toast.error({ render: "Error: No se pudo obtener el usuario, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    setUpdating(true);
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    await axios.put(`${apiRequest()}/users/${updateUser.id}`, updateUser, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        handleCloseUpdate();
        setUpdateUser({});
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getUsers();
        setUpdating(false);
      }
    }).catch((err) => {
      setUpdating(false);
      console.error('error', err);
    });
  }

  const saveAccount = async (event) => {
    event.preventDefault();
    setCreating(true);
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    await axios.post(`${apiRequest()}/account/register/${isPassed.businessId}/internal`, createInternalAccount, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        handleCloseCreate();
        setCreateInternalAccount({});
        event.target.reset();
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getUsers();
        setCreating(false);
      }
    }).catch((_err) => {
      setCreating(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  return (
    <Container className='mt-5'>
      <Row>
        <Col xs={12}>
          <div className="page-title">
            <h3 className='text-center'>Mis usuarios</h3>
            <p className="text-center text-muted">
              ¡Bienvenido a nuestra plataforma de usuarios! En esta sección, podrás explorar la lista completa de usuarios asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de usuarios.
              <br />¡Que disfrutes explorando la lista de usuarios!
              <br />
              <Button variant="success" size="sm" onClick={handleShowCreate}>Nueva usuario</Button>
            </p>
          </div>
          <Card>
            <Card.Body>
              {(users.length === 0 && !loaded) ? (
                <>
                  <Placeholder as={Row} size="xs" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} size="sm" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} size="lg" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                </>
              ) : (
                <>
                  <Table striped size='sm' responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombre</th>
                        <th>Apellidos</th>
                        <th>Genero</th>
                        <th>Avatar</th>
                        <th>Correo</th>
                        <th>Telefono</th>
                        <th>Rol</th>
                        <th>Status</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, index) => {
                        return (
                          <tr key={user.id}>
                            <td>{index + 1}.</td>
                            <td>{user.people?.name}</td>
                            <td>{user.people?.lastName}</td>
                            <td>{user.people?.gender}</td>
                            <td>{'Nothing'}</td>
                            <td>{user.email}</td>
                            <td>{user.phone ? user.phone : 'No disponible'}</td>
                            <td>{user.role.toUpperCase()}</td>
                            <td>{user.enabled ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</td>
                            <td>
                              <Button title="Ver detalles" className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>
                              {' '}
                              <Button onClick={() => getUser(user.id)} title="Editar ruta" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </Card.Body>
          </Card>
          <Offcanvas placement="end" show={create} onHide={handleCloseCreate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Nuevo usuario</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              ¡Bienvenido al proceso de registro de usuarios!
              <br />
              El registro de usuarios es el primer paso para aprovechar al máximo todas las funcionalidades que ofrecemos. Por favor, completa el formulario a continuación con la información necesaria para crear tu cuenta y comenzar a gestionar tus rutas de manera eficiente. Recuerda proporcionar datos precisos y actualizados para garantizar una experiencia óptima. Una vez que completes el registro, podrás acceder a tu cuenta y empezar a explorar todas las características diseñadas para simplificar la gestión de usuarios.
              <br />
              <br />
              <Form onSubmit={saveAccount}>
                <Form.Label>Identificación</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={createInternalAccount.user?.people?.identification || ''} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, people: { ...createInternalAccount.user?.people, identification: e.target.value } } })} required />
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createInternalAccount.user?.people?.name || ''} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, people: { ...createInternalAccount.user?.people, name: e.target.value } } })} required />
                <Form.Label>Apellidos</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createInternalAccount.user?.people?.lastName || ''} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, people: { ...createInternalAccount.user?.people, lastName: e.target.value } } })} required />
                <Form.Label>Genero</Form.Label>
                <Form.Select className="mb-3" size="sm" defaultValue={createInternalAccount.user?.people?.gender || ''} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, people: { ...createInternalAccount.user?.people, gender: e.target.value } } })} required>
                  <option value="">Seleccione</option>
                  <option value="HOMBRE">Hombre</option>
                  <option value="MUJER">Mujer</option>
                </Form.Select>
                <Form.Label>Telefono</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultChecked={createInternalAccount.user?.phone || ''} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, phone: e.target.value } })} required />
                <Form.Label>Rol</Form.Label>
                <Form.Select className="mb-3" size="sm" defaultChecked={createInternalAccount.user?.role || ''} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, role: e.target.value } })} required>
                  <option value="">Seleccione</option>
                  <option value="seller">Vendedor</option>
                  <option value="deliverer">Entregador</option>
                  <option value="packer">Empacador</option>
                </Form.Select>
                <Form.Label>Correo electronico</Form.Label>
                <Form.Control className="mb-3" size="sm" type="email" defaultValue={createInternalAccount.user?.email || ''} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, email: e.target.value } })} required />
                <Form.Label>Contraseña</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createInternalAccount.user?.credentials?.value || ''} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, credentials: { ...createInternalAccount.user.credentials, value: e.target.value } } })} required />
                <Form.Label>Habilitado</Form.Label>
                <Form.Switch checked={createInternalAccount?.user?.enabled || false} value={createInternalAccount.user?.enabled || false} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, enabled: e.target.checked } })} />
                <Form.Label>Temporal</Form.Label>
                <Form.Switch checked={createInternalAccount?.user?.credentials?.temporary || false} value={createInternalAccount?.user?.credentials?.temporary || false} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, credentials: { ...createInternalAccount.user?.credentials, temporary: e.target.checked } } })} />
                <Form.Label>Email verificado</Form.Label>
                <Form.Switch checked={createInternalAccount?.user?.emailVerified || false} value={createInternalAccount?.user?.emailVerified || false} onChange={(e) => setCreateInternalAccount({ ...createInternalAccount, user: { ...createInternalAccount.user, emailVerified: e.target.checked } })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={creating}>{creating ? 'Guardando...' : 'Guardar'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseCreate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas placement="end" show={update} onHide={handleCloseUpdate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Actualizar usuario</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              En este espacio, podrás realizar cambios y actualizaciones en la información de tus usuarios de manera rápida y sencilla. Mantener la información de tus usuarios al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
              <br />
              <br />
              <Form onSubmit={saveChanges}>
                <Form.Label>Identificación</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={updateUser.people?.identification} onChange={(e) => setUpdateUser({ ...updateUser, people: { ...updateUser.people, identification: e.target.value } })} required />
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateUser.people?.name} onChange={(e) => setUpdateUser({ ...updateUser, people: { ...updateUser.people, name: e.target.value } })} required />
                <Form.Label>Apellidos</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateUser.people?.lastName} onChange={(e) => setUpdateUser({ ...updateUser, people: { ...updateUser.people, lastName: e.target.value } })} required />
                <Form.Label>Genero</Form.Label>
                <Form.Select className="mb-3" size="sm" value={updateUser.people?.gender} onChange={(e) => setUpdateUser({ ...updateUser, people: { ...updateUser.people, gender: e.target.value } })} required>
                  <option value="">Seleccione</option>
                  <option value="HOMBRE">Hombre</option>
                  <option value="MUJER">Mujer</option>
                </Form.Select>
                <Form.Label>Habilitado</Form.Label>
                <Form.Switch checked={updateUser?.enabled || false} value={updateUser?.enabled} onChange={(e) => setUpdateUser({ ...updateUser, enabled: e.target.checked })} />
                <Form.Label>Email verificado</Form.Label>
                <Form.Switch checked={updateUser?.emailVerified || false} value={updateUser?.emailVerified} onChange={(e) => setUpdateUser({ ...updateUser, emailVerified: e.target.checked })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={updating}>{updating ? 'Guardando cambios...' : 'Guardar cambios'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseUpdate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default Users;
