import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Placeholder, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Assorts = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);

    const [routes, setRoutes] = useState([]);
    const [assorts, setAssorts] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [deliverers, setDeliverers] = useState([]);
    const [days, setDays] = useState([]);
    const [loaded, setLoaded] = useState(true);

    const [createAssort, setCreateAssort] = useState({});
    const [create, setCreate] = useState(false);
    const handleCloseCreate = () => setCreate(false);
    const handleShowCreate = () => setCreate(true);
    const [creating, setCreating] = useState(false);

    const [updateAssort, setUpdateAssort] = useState({});
    const [update, setUpdate] = useState(false);
    const handleCloseUpdate = () => setUpdate(false);
    const handleShowUpdate = () => setUpdate(true);
    const [updating, setUpdating] = useState(false);

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId) {
            getAssorts();
            getRoutes();
            getSellers();
            getDays();
        }
    }, [context, initialized, isPassed.businessId]);

    const getAssorts = async () => {
        setLoaded(false);
        await axios.get(`${apiRequest()}/assorts/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setAssorts(res.data);
            console.log(res.data);
            setLoaded(true);
        }).catch((err) => {
            setLoaded(true);
            toast.error({ render: "Error: No se pudo obtener los cuadres, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getRoutes = async () => {
        await axios.get(`${apiRequest()}/routes/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setRoutes(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener las rutas, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getSellers = async () => {
        await axios.get(`${apiRequest()}/sellers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setSellers(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los vendedores, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getDeliverers = async () => {
        await axios.get(`${apiRequest()}/deliverers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setDeliverers(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los vendedores, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getDays = async () => {
        await axios.get(`${apiRequest()}/days`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setDays(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los dias, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getCustomer = async (id) => {
        handleShowUpdate();
        setUpdateAssort({});
        setUpdating(true);
        await axios.get(`${apiRequest()}/customers/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setUpdateAssort(res.data);
            setUpdating(false);
        }).catch((_err) => {
            setUpdating(false);
            toast.error({ render: "Error: No se pudo obtener el cliente, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const saveChanges = async (event) => {
        event.preventDefault();
        setUpdating(true);
        const toastId = toast.loading("Guardando cambios, por favor espere...", { hideProgressBar: false, position: "bottom-center" });
        await axios.put(`${apiRequest()}/assorts/${updateAssort.id}`, updateAssort, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            if (res.status === 200) {
                setUpdateAssort({});
                handleCloseUpdate();
                toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                getAssorts();
                setUpdating(false);
            }
        }).catch((_err) => {
            setUpdating(false);
            toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const saveAssort = async (event) => {
        event.preventDefault();
        setCreating(true);
        const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
        await axios.post(`${apiRequest()}/customers/route/${createAssort?.route?.id}`, createAssort, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            if (res.status === 200) {
                setCreateAssort({});
                event.target.reset();
                handleCloseCreate();
                toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                getAssorts();
                setCreating(false);
            }
        }).catch((_err) => {
            setCreating(false);
            toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    return (
        <Container className='mt-5'>
            <Row>
                <Col xs={12}>
                    <div className="page-title">
                        <h3 className='text-center'>Mis cuadres</h3>
                        <p className="text-center text-muted">
                            ¡Bienvenido a nuestra plataforma de cuadres! En esta sección, podrás explorar la lista completa de cuadres asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de cuadres.
                            <br />¡Que disfrutes explorando la lista de cuadres!
                            <br />
                            <Button variant="success" size="sm" onClick={handleShowCreate}>Nuevo cliente</Button>
                        </p>
                    </div>
                    <Card>
                        <Card.Body>
                            {(assorts.length === 0 && !loaded) ? (
                                <>
                                    <Placeholder as={Row} size="xs" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} size="sm" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} size="lg" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                </>
                            ) : (
                                <>
                                    <Table striped size='sm' responsive>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Codigo</th>
                                                <th>Ruta</th>
                                                <th>Razon social</th>
                                                <th>Nombre cliente</th>
                                                <th>Direccíon</th>
                                                <th>Correo</th>
                                                <th>Telefono</th>
                                                <th>Estado</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {assorts.map((assort, index) => {
                                                return (
                                                    <tr key={assort.id}>
                                                        <td>{index + 1}.</td>
                                                        <td>{assort.code}</td>
                                                        <td>{assort.route.name}</td>
                                                        <td>{assort.corporateName}</td>
                                                        <td>{assort.owner}</td>
                                                        <td>{assort.address}</td>
                                                        <td>{assort.email}</td>
                                                        <td>{assort.phone}</td>
                                                        <td>{assort.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</td>
                                                        <td>
                                                            <Button title="Ver detalles" className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>
                                                            {' '}
                                                            <Button onClick={() => getCustomer(assort.id)} title="Editar datos de cliente" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button>{' '}
                                                            <Link to={`/sales/${assort.id}/create`}><Button title="Ir para facturar" className="btn-outline-primary" size="sm" variant="light"><i className="bi bi-cart3"></i></Button></Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </Card.Body>

                    </Card>
                    <Offcanvas placement="end" show={create} onHide={handleCloseCreate} backdrop="static">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Nuevo cuadre</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            ¡Bienvenido al proceso de registro de cuadre!
                            <br />
                            El registro de cuadre es el primer paso para aprovechar al máximo todas las funcionalidades que ofrecemos. Por favor, completa el formulario a continuación con la información necesaria para crear tu cuenta y comenzar a gestionar tus clientes de manera eficiente. Recuerda proporcionar datos precisos y actualizados para garantizar una experiencia óptima. Una vez que completes el registro, podrás acceder a tu cuenta y empezar a explorar todas las características diseñadas para simplificar la gestión de cuadre.
                            <br />
                            <br />
                            <Form onSubmit={saveAssort}>
                                <Form.Label>Ruta</Form.Label>
                                <Form.Select className="mb-3" defaultValue={createAssort?.route?.id} onChange={(e) => setCreateAssort({ ...createAssort, route: { id: e.target.value } })} required>
                                    <option value="">Selecciona una ruta</option>
                                    {routes.map((route, _index) => {
                                        return (
                                            <option key={route.id} value={route.id}>{route.name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Select className="mb-3" defaultValue={createAssort?.route?.id} onChange={(e) => setCreateAssort({ ...createAssort, route: { id: e.target.value } })} required>
                                    <option value="">Selecciona un dia</option>
                                    {days.map((day, _index) => {
                                        return (
                                            <option key={day.id} value={day.id}>{day.name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Select className="mb-3" defaultValue={createAssort?.route?.id} onChange={(e) => setCreateAssort({ ...createAssort, route: { id: e.target.value } })} required>
                                    <option value="">Selecciona un vendedor</option>
                                    {sellers.map((seller, _index) => {
                                        return (
                                            <option key={seller.id} value={seller.id}>{seller?.people?.name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Select className="mb-3" defaultValue={createAssort?.route?.id} onChange={(e) => setCreateAssort({ ...createAssort, route: { id: e.target.value } })} required>
                                    <option value="">Selecciona un repartidor</option>
                                    {deliverers.map((deliverer, _index) => {
                                        return (
                                            <option key={deliverer.id} value={deliverer.id}>{deliverer?.people?.name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Label>Razon social</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createAssort.corporateName} onChange={(e) => setCreateAssort({ ...createAssort, corporateName: e.target.value })} required />
                                <Form.Label>Representante</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createAssort.owner} onChange={(e) => setCreateAssort({ ...createAssort, owner: e.target.value })} required />
                                <Form.Label>Correo</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="email" defaultValue={createAssort.email} onChange={(e) => setCreateAssort({ ...createAssort, email: e.target.value })} required />
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createAssort.phone} onChange={(e) => setCreateAssort({ ...createAssort, phone: e.target.value })} required />
                                <Form.Label>Direccíon</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createAssort.address} onChange={(e) => setCreateAssort({ ...createAssort, address: e.target.value })} required />
                                <Form.Label>Ciudad</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createAssort.city} onChange={(e) => setCreateAssort({ ...createAssort, city: e.target.value })} required />
                                <Form.Label>Estado</Form.Label>
                                <Form.Switch checked={createAssort.status || false} value={createAssort.status} onChange={(e) => setCreateAssort({ ...createAssort, status: e.target.checked })} />
                                <br />
                                <Button variant="success" size="sm" type="submit" disabled={creating}>{creating ? 'Guardando...' : 'Guardar'}</Button>{' '}
                                <Button variant="secondary" size="sm" onClick={handleCloseCreate}>Cancelar</Button>{' '}
                            </Form>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Offcanvas placement="end" show={update} onHide={handleCloseUpdate} backdrop="static">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Actualizar cliente</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            En este espacio, podrás realizar cambios y actualizaciones en la información de tus clientes de manera rápida y sencilla. Mantener la información de tus clientes al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
                            <br />
                            <br />
                            <Form onSubmit={saveChanges}>
                                <Form.Label>Ruta</Form.Label>
                                <Form.Select className="mb-3" value={updateAssort.route?.id || ''} onChange={(e) => setUpdateAssort({ ...updateAssort, route: { id: e.target.value } })} required>
                                    {routes.map((route, _index) => {
                                        return (
                                            <option key={route.id} value={route.id}>{route.name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Label>Code</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateAssort.code} disabled />
                                <Form.Label>Razon social</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateAssort.corporateName} onChange={(e) => setUpdateAssort({ ...updateAssort, corporateName: e.target.value })} required />
                                <Form.Label>Representante</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateAssort.owner} onChange={(e) => setUpdateAssort({ ...updateAssort, owner: e.target.value })} required />
                                <Form.Label>Correo</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="email" defaultValue={updateAssort.email} onChange={(e) => setUpdateAssort({ ...updateAssort, email: e.target.value })} required />
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateAssort.phone} onChange={(e) => setUpdateAssort({ ...updateAssort, phone: e.target.value })} required />
                                <Form.Label>Direccíon</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateAssort.address} onChange={(e) => setUpdateAssort({ ...updateAssort, address: e.target.value })} required />
                                <Form.Label>Ciudad</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateAssort.city} onChange={(e) => setUpdateAssort({ ...updateAssort, city: e.target.value })} required />
                                <Form.Label>Estado</Form.Label>
                                <Form.Switch checked={updateAssort.status || false} value={updateAssort.status} onChange={(e) => setUpdateAssort({ ...updateAssort, status: e.target.checked })} />
                                <br />
                                <Button variant="success" size="sm" type="submit" disabled={updating}>{updating ? 'Guardando cambios...' : 'Guardar cambios'}</Button>{' '}
                                <Button variant="secondary" size="sm" onClick={handleCloseUpdate}>Cancelar</Button>{' '}
                            </Form>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
}

export default Assorts;
