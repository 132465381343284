import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';

const Sellers = () => {

  const { keycloak, initialized } = useKeycloak();
  const [context, setContext] = useContext(StatusContext);

  const [sellers, setSellers] = useState([]);

  const [updateSeller, setUpdateSeller] = useState({});
  const [update, setUpdate] = useState(false);
  const handleCloseUpdate = () => setUpdate(false);
  const handleShowUpdate = () => setUpdate(true);
  const [updating, setUpdating] = useState(false);

  const isPassed = useMemo(() => {
    return context;
  }, [context]);

  useEffect(() => {
    if (initialized && isPassed.businessId) {
      getSellers();
    }
  }, [context, initialized, isPassed.businessId]);

  const getSellers = async () => {
    await axios.get(`${apiRequest()}/sellers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setSellers(res.data);
    }).catch((err) => {
      console.error('error', err);
    });
  }

  const getSeller = async (id) => {
    handleShowUpdate();
    setUpdateSeller({});
    await axios.get(`${apiRequest()}/sellers/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setUpdateSeller(res.data);
    }).catch((err) => {
      console.error('error', err);
    });
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    setUpdating(true);
    const toastId = toast.loading("Guardando cambios, por favor espere...", { hideProgressBar: false, position: "bottom-center" });
    await axios.put(`${apiRequest()}/sellers/${updateSeller.id}`, updateSeller, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        handleCloseUpdate();
        event.target.reset();
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getSellers();
        setUpdating(false);
      }
    }).catch((_err) => {
      setUpdating(false);
      
    });
  }

  return (
    <Container className='mt-5'>
      <Row>
        <Col xs={12}>
          <div className="page-title">
            <h3 className='text-center'>Mis vendedores</h3>
            <p className="text-center text-muted">
              ¡Bienvenido a nuestra plataforma de vendedores! En esta sección, podrás explorar la lista completa de vendedores asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de vendedores.
              <br />¡Que disfrutes explorando la lista de vendedores!
              <br />
              {/* <Button variant="success" onClick={handleShowCreate}>Nuevo vendedor</Button> */}
            </p>
          </div>
          <Card>
            <Card.Body>
              <Table striped size='sm' responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Identificación</th>
                    <th>Nombre</th>
                    <th>Apellidos</th>
                    <th>Genero</th>
                    <th>Telefono</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {sellers.map((seller, index) => {
                    return (
                      <tr key={seller.id}>
                        <td>{index + 1}.</td>
                        <td>{seller.people?.identification}</td>
                        <td>{seller.people?.name}</td>
                        <td>{seller.people?.lastName}</td>
                        <td>{seller.people.gender}</td>
                        <td>{seller.phone}</td>
                        <td>{seller.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</td>
                        <td>
                          <Button title="Ver detalles" className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>
                          {' '}
                          <Button onClick={() => getSeller(seller.id)} title="Editar datos de vendedor" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Offcanvas placement="end" show={update} onHide={handleCloseUpdate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Actualizar vendedor</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              En este espacio, podrás realizar cambios y actualizaciones en la información de tus rutas de manera rápida y sencilla. Mantener la información de tus rutas al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
              <br />
              <br />
              <Form onSubmit={saveChanges}>
                <Form.Label>Identificación</Form.Label>
                <Form.Control className="mb-3" size="sm" type="number" defaultValue={updateSeller.people?.identification} onChange={(e) => setUpdateSeller({ ...updateSeller, people: { ...updateSeller.people, identification: e.target.value } })} required />
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateSeller.people?.name} onChange={(e) => setUpdateSeller({ ...updateSeller, people: { ...updateSeller.people, name: e.target.value } })} required />
                <Form.Label>Apellidos</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateSeller.people?.lastName} onChange={(e) => setUpdateSeller({ ...updateSeller, people: { ...updateSeller.people, lastName: e.target.value } })} required />
                <Form.Label>Genero</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateSeller.people?.gender} onChange={(e) => setUpdateSeller({ ...updateSeller, people: { ...updateSeller.people, gender: e.target.value } })} required />
                <Form.Label>Telefono</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateSeller.phone} onChange={(e) => setUpdateSeller({ ...updateSeller, phone: e.target.value })} required />
                <Form.Label>Correo</Form.Label>
                <Form.Control className="mb-3" size="sm" type="email" defaultValue={updateSeller.email} onChange={(e) => setUpdateSeller({ ...updateSeller, email: e.target.value })} required />
                <Form.Label>Estado</Form.Label>
                <Form.Switch checked={updateSeller.status || false} value={updateSeller.status} onChange={(e) => setUpdateSeller({ ...updateSeller, status: e.target.checked })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={updating}>{updating ? 'Guardando cambios...' : 'Guardar cambios'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseUpdate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>          
        </Col>
      </Row>
      <ToastContainer/>
    </Container>
  );
}

export default Sellers;
