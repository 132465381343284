import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';

const Suppliers = () => {

  const { keycloak, initialized } = useKeycloak();
  const [context, setContext] = useContext(StatusContext);

  const [suppliers, setSuppliers] = useState([]);

  const [createSupplier, setCreateSupplier] = useState({});
  const [create, setCreate] = useState(false);
  const handleCloseCreate = () => setCreate(false);
  const handleShowCreate = () => setCreate(true);
  const [creating, setCreating] = useState(false);

  const [updateSupplier, setUpdateSupplier] = useState({});
  const [update, setUpdate] = useState(false);
  const handleCloseUpdate = () => setUpdate(false);
  const handleShowUpdate = () => setUpdate(true);
  const [updating, setUpdating] = useState(false);

  const isPassed = useMemo(() => {
    return context;
  }, [context]);

  useEffect(() => {
    if (initialized && isPassed.businessId) {
      getSuppliers();
    }
  }, [context, initialized, isPassed.businessId]);

  const getSuppliers = async () => {
    await axios.get(`${apiRequest()}/suppliers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setSuppliers(res.data);
    }).catch((_err) => {
      toast.error({ render: "Error: No se pudo obtener los proveedores, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const getSupplier = async (id) => {
    handleShowUpdate();
    setUpdateSupplier({});
    setUpdating(true);
    await axios.get(`${apiRequest()}/suppliers/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setUpdateSupplier(res.data);
      setUpdating(false);
    }).catch((_err) => {
      setUpdating(false);
      toast.error({ render: "Error: No se pudo obtener el proveedor, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    setUpdateSupplier({});
    setUpdating(true);
    const toastId = toast.loading("Guardando cambios, por favor espere...", { hideProgressBar: false, position: "bottom-center" });
    await axios.put(`${apiRequest()}/suppliers/${updateSupplier.id}`, updateSupplier, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setUpdateSupplier({});
        handleCloseUpdate();
        event.target.reset();
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getSuppliers();
        setUpdating(false);
      }
    }).catch((_err) => {
      setUpdating(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveSupplier = async (event) => {
    event.preventDefault();
    setCreating(true);
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    await axios.post(`${apiRequest()}/suppliers/business/${isPassed.businessId}`, createSupplier, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        handleCloseCreate();
        setCreateSupplier({});
        event.target.reset();
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getSuppliers();
        setCreating(false);
      }
    }).catch((err) => {
      setCreating(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  return (
    <Container className='mt-5'>
      <Row>
        <Col xs={12}>
          <div className="page-title">
            <h3 className='text-center'>Mis proveedores</h3>
            <p className="text-center text-muted">
              ¡Bienvenido a nuestra plataforma de proveedores! En esta sección, podrás explorar la lista completa de proveedores asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de proveedores.
              <br />¡Que disfrutes explorando la lista de proveedores!
              <br />
              <Button variant="success" size="sm" onClick={handleShowCreate}>Nuevo proveedor</Button>
            </p>
          </div>
          <Card>
            <Card.Body>
              <Table striped responsive size='sm'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Dirección</th>
                    <th>Correo</th>
                    <th>Telefono</th>
                    <th>Ciudad</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {suppliers.map((supplier, index) => {
                    return (
                      <tr key={supplier.id}>
                        <td>{index + 1}.</td>
                        <td>{supplier.name}</td>
                        <td>{supplier.address}</td>
                        <td>{supplier.email}</td>
                        <td>{supplier.phone}</td>
                        <td>{supplier.city}</td>
                        <td>{supplier.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</td>
                        <td>
                          <Button title="Ver detalles" className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>
                          {' '}
                          <Button onClick={() => getSupplier(supplier.id)} title="Editar datos de cliente" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Offcanvas placement="end" show={create} onHide={handleCloseCreate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Nuevo proveedor</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            ¡Bienvenido al proceso de registro de proveedores!
            <br />
            El registro de proveedores es el primer paso para aprovechar al máximo todas las funcionalidades que ofrecemos. Por favor, completa el formulario a continuación con la información necesaria para crear tu cuenta y comenzar a gestionar tus proveedores de manera eficiente. Recuerda proporcionar datos precisos y actualizados para garantizar una experiencia óptima. Una vez que completes el registro, podrás acceder a tu cuenta y empezar a explorar todas las características diseñadas para simplificar la gestión de proveedores.
              <br />
              <br />
              <Form onSubmit={saveSupplier}>
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createSupplier.name} onChange={(e) => setCreateSupplier({ ...createSupplier, name: e.target.value })} required />
                <Form.Label>Dirección</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createSupplier.address} onChange={(e) => setCreateSupplier({ ...createSupplier, address: e.target.value })} required />
                <Form.Label>Correo</Form.Label>
                <Form.Control className="mb-3" size="sm" type="email" defaultValue={createSupplier.email} onChange={(e) => setCreateSupplier({ ...createSupplier, email: e.target.value })} required />
                <Form.Label>Telefono</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createSupplier.phone} onChange={(e) => setCreateSupplier({ ...createSupplier, phone: e.target.value })} required />
                <Form.Label>Ciudad</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createSupplier.city} onChange={(e) => setCreateSupplier({ ...createSupplier, city: e.target.value })} required />
                <Form.Label>Estado</Form.Label>
                <Form.Switch defaultChecked={createSupplier.status} onChange={(e) => setCreateSupplier({ ...createSupplier, status: e.target.checked })} />
                <br />
                <Button variant="primary" size="sm" type="submit" disabled={creating}>{creating ? 'Guardando...' : 'Guardar'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseCreate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas placement="end" show={update} onHide={handleCloseUpdate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Actualizar proveedor</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              En este espacio, podrás realizar cambios y actualizaciones en la información de tus proveedores de manera rápida y sencilla. Mantener la información de tus proveedores al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
              <br />
              <br />
              <Form onSubmit={saveChanges}>
              <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateSupplier.name} onChange={(e) => setUpdateSupplier({ ...updateSupplier, name: e.target.value })} required />
                <Form.Label>Dirección</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateSupplier.address} onChange={(e) => setUpdateSupplier({ ...updateSupplier, address: e.target.value })} required />
                <Form.Label>Correo</Form.Label>
                <Form.Control className="mb-3" size="sm" type="email" defaultValue={updateSupplier.email} onChange={(e) => setUpdateSupplier({ ...updateSupplier, email: e.target.value })} required />
                <Form.Label>Telefono</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateSupplier.phone} onChange={(e) => setUpdateSupplier({ ...updateSupplier, phone: e.target.value })} required />
                <Form.Label>Ciudad</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateSupplier.city} onChange={(e) => setUpdateSupplier({ ...updateSupplier, city: e.target.value })} required />
                <Form.Label>Estado</Form.Label>
                <Form.Switch checked={updateSupplier.status || false} value={updateSupplier.status} onChange={(e) => setUpdateSupplier({ ...updateSupplier, status: e.target.checked })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={updating}>{updating ? 'Guardando cambios...' : 'Guardar cambios'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseUpdate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>          
        </Col>
      </Row>
      <ToastContainer/>
    </Container>
  );
}

export default Suppliers;
