import React, { useEffect, useState } from "react";
import "./register.css";
import axios from "axios";
import { apiRequest } from "../../services/config";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment-timezone";

const Register = () => {

    const [registered, setRegistered] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [exists, setExists] = useState(false);
    const { keycloak, initialized } = useKeycloak();

    const [createAccount, setCreateAccount] = useState({
        user: {
            username: '',
            email: '',
            phone: '',
            role: 'manager',
            enabled: true,
            emailVerified: false,
            credentials: {
                type: 'password',
                userLabel: 'Contraseña',
                value: '',
                temporary: false
            },
            people: {
                business: {
                    nit: '',
                    name: '',
                    logo: '',
                    postalCode: '',
                    address: '',
                    email: '',
                    phone: '',
                    subscription: 'FREE',
                    status: true
                },
                identification: '',
                name: '',
                lastName: '',
                gender: ''
            }
        }
    });
    const [imagePreview, setImagePreview] = useState('');

    useEffect(() => {
        console.log(initialized);
    }, []);

    // Manejar el cambio de archivos
    /**const handleImageChange = (event) => {
        const reader = new FileReader();
        const file = event.target.files[0];

        reader.onloadend = (event) => {
            setImagePreview(reader.result);
            setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, business: { ...createAccount.user.people.business, logo: event.target.result } } } });
        }

        reader.readAsDataURL(file);
    }*/

    const onRegister = async (e) => {
        e.preventDefault();
        setRegistering(true);
        await axios.post(`${apiRequest()}/account/register`, createAccount, { withCredentials: true }).then((res) => {
            if (res.status === 200) {
                setRegistered(true);
            }
            setRegistering(false);
        }).catch((err) => {
            if (err.response.status === 400) {
                setExists(true);
            }
            setRegistered(false);
            setRegistering(false);
        });
    }

    return (
        <div className="form-signin w-100 m-auto">
            <Form onSubmit={onRegister}>
                <div className="text-center">
                    <img className="mb-4" src="logoNegrdo.png" alt="" width="100" height="100" />
                    <h1 className="h3 mb-3 fw-normal">Registrarse</h1>
                    <p style={{ fontSize: '12px' }}>Por favor, complete los siguientes datos y verifique que los datos sean correctos</p>
                </div>
                <h3 className="mb-3">Datos del propietario</h3>
                <Row className="mb-3">
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Identificación</Form.Label>
                        <Form.Control type="text" pattern="[0-9]*" size="sm" defaultValue={createAccount.user?.people?.identification} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, identification: e.target.value } } })} minLength={8} placeholder="Identificación" disabled={registering} required />
                    </Col>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Nombre</Form.Label>
                        <Form.Control type="text" size="sm" defaultValue={createAccount.user?.people?.name} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, name: e.target.value } } })} placeholder="Nombre" disabled={registering} required />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Apellidos</Form.Label>
                        <Form.Control type="text" size="sm" defaultValue={createAccount.user?.people?.lastName} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, lastName: e.target.value } } })} placeholder="Apellidos" disabled={registering} required />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Genero</Form.Label>
                        <Form.Select size="sm" onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, gender: e.target.value } } })} disabled={registering} required >
                            <option value="">Seleccione</option>
                            <option value="HOMBRE">HOMBRE</option>
                            <option value="MUJER">MUJER</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Telefono</Form.Label>
                        <Form.Control minLength={8} maxLength={13} type="text" size="sm" defaultValue={createAccount.user?.phone} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, phone: e.target.value } })} placeholder="Telefono" disabled={registering} required />
                    </Col>
                </Row>
                <h3 >Datos de empresa</h3>
                <p className="text-muted" style={{ fontSize: '12px' }}>Por favor asegurese de que la información aqui suministrada sea veraz ya que esta aparecerá en sus facturas y demás información</p>
                <Row className="mb-3">
                    <Col>
                        <Form.Label><span className="text-danger">*</span> NIT</Form.Label>
                        <Form.Control type="text" pattern="\d{8,10}-[1-9]$" size="sm" defaultValue={createAccount.user?.people?.business?.nit} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, business: { ...createAccount.user.people.business, nit: e.target.value } } } })} placeholder="NIT" disabled={registering} required />
                    </Col>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Razon social</Form.Label>
                        <Form.Control type="text" size="sm" defaultValue={createAccount.user?.people?.business?.name} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, business: { ...createAccount.user.people.business, name: e.target.value } } } })} placeholder="Razon social" disabled={registering} required />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Dirección</Form.Label>
                        <Form.Control type="text" size="sm" value={createAccount.user?.people?.business?.address} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, business: { ...createAccount.user.people.business, address: e.target.value } } } })} placeholder="Dirección" disabled={registering} required />
                    </Col>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Telefono</Form.Label>
                        <Form.Control type="text" minLength={8} maxLength={13} size="sm" value={createAccount.user?.people?.business?.phone} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, business: { ...createAccount.user.people.business, phone: e.target.value } } } })} placeholder="Telefono" disabled={registering} required />
                        <span className="text-muted" style={{ fontSize: '10px' }}>Este será aparecerá en las facturas</span>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Correo de la empresa</Form.Label>
                        <Form.Control type="email" size="sm" defaultValue={createAccount.user?.people?.business?.email} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, business: { ...createAccount.user.people.business, email: e.target.value } } } })} placeholder="Correo empresarial" disabled={registering} required />
                        <span className="text-muted" style={{ fontSize: '10px' }}>Este será aparecerá en las facturas</span>
                    </Col>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Codigo postal</Form.Label>
                        <Form.Control type="text" pattern="[0-9]*" size="sm" defaultValue={createAccount.user?.people?.business?.postalCode} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, people: { ...createAccount.user.people, business: { ...createAccount.user.people.business, postalCode: e.target.value } } } })} placeholder="Codigo postal" disabled={registering} required />
                    </Col>
                </Row>
                {/* <Row className="mb-3">
                        <Col>
                            <Form.Label><span className="text-danger">*</span> Logo</Form.Label>
                            <Form.Control size="sm" type="file" accept="image/*" onChange={handleImageChange} className="mb-3" disabled={registering} required />
                        </Col>
                    </Row> */}
                {imagePreview && (
                    <Row className="mb-3">
                        <img src={imagePreview} alt="Vista previa de la imagen" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                    </Row>
                )}

                <h3 className="mb-3">Datos de la cuenta</h3>
                <Row className="mb-3">
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Correo para la cuenta</Form.Label>
                        <Form.Control type="email" size="sm" defaultValue={createAccount.user?.email} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, email: e.target.value } })} placeholder="Correo para la cuenta" disabled={registering} required />
                    </Col>
                    <Col>
                        <Form.Label><span className="text-danger">*</span> Contraseña</Form.Label>
                        <Form.Control type="password" minLength={8} size="sm" defaultValue={createAccount.user.credentials.value} onChange={(e) => setCreateAccount({ ...createAccount, user: { ...createAccount.user, credentials: { ...createAccount.user.credentials, value: e.target.value } } })} placeholder="Contraseña" disabled={registering} required />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Check type="checkbox" id={`default-checkbox`} label="Acepto los terminos y condiciones" required
                        />
                    </Col>
                </Row>
                <Alert variant="danger" show={exists} onClose={() => setExists(false)} dismissible>
                    The email is already registered.
                </Alert>
                {registered && (
                    <button className="btn btn-success w-100 py-2 btn-sm" type="button" onClick={() => keycloak.login({ redirectUri: `${window.location.origin}` })}>Iniciar sesion</button>
                )}
                {!registered && (
                    <button className="btn btn-success w-100 py-2 btn-sm" type="submit" disabled={false}>{registering ? 'Registrando, por favor espere...' : 'Registrarse'}</button>
                )}
                <p className="mt-5 mb-3 text-body-secondary text-center">Todos los derechos reservados &copy; {moment().format('YYYY')}</p>
            </Form>
        </div>
    );

}
export default Register;