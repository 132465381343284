import { createContext, useState } from "react";

export const StatusContext = createContext(false);

const StatusProvider = ({ children }) => {

    const [context, setContext] = useState({ isLogged: false, businessId: '', others: {
        sellerId: ''
    } });

    return (
        <StatusContext.Provider value={[context, setContext]}>
            {children}
        </StatusContext.Provider>
    );
}

export default StatusProvider;