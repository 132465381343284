import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { Button, Carousel, Col, Container, Form, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Welcome = () => {

    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        console.log(initialized);
    });

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#"><strong className="text-success">Negrdo</strong> <sub className="text-muted" style={{ fontSize: '10px' }}>Distributions</sub></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">

                        {!keycloak.authenticated && (
                            <>
                                <Nav
                                    className="me-auto my-2 my-lg-0"
                                    style={{ maxHeight: '100px' }}
                                    navbarScroll
                                >
                                    <>
                                        <Nav className="me-auto"><Link to={'/'} className="nav-link"><i className="bi bi-grid-1x2 text-success"></i> Precios</Link></Nav>
                                        <Nav className="me-auto"><Link to={'/'} className="nav-link"><i className="bi bi-person-vcard text-success"></i> Plan</Link></Nav>
                                        <Nav className="me-auto"><Link to={'/'} className="nav-link"><i className="bi bi-boxes text-success"></i> Tutoriales</Link></Nav>
                                        {/* <Nav className="me-auto"><Link to={'/suppliers'} className="nav-link"><i className="bi bi-people-fill text-success"></i> Proveedores</Link></Nav>
                                    <Nav className="me-auto"><Link to={'/sales'} className="nav-link"> <i className="bi bi-cart3 text-success" /> Ventas</Link></Nav>
                                    <Nav className="me-auto"><Link to={'/routes'} className="nav-link"><i className="bi bi-sign-intersection-y text-success"></i> Rutas</Link></Nav>
                                    <NavDropdown title={<span><i className="bi bi-people text-success" /> Empleados</span>} id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} to={'/sellers'}>Vendedores</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={'/deliverers'}>Repartidores</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={'/packers'}>Empacadores</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav className="me-auto"><Link to={'/users'} className="nav-link"><i className="bi bi-shield-lock text-success"></i> Usuarios</Link></Nav> */}
                                    </>
                                </Nav>

                                <Nav id="collasible-nav-dropdown" className="ms-auto">
                                    {/* <Form className="d-flex">
                                        <Form.Control
                                            type="search"
                                            placeholder="Search"
                                            className="me-2"
                                            aria-label="Search"
                                        />
                                        <Button variant="outline-success">Search</Button>
                                    </Form> */}
                                    <Link onClick={() => keycloak.login({ redirectUri: `${window.location.origin}` })} className="nav-link">Login</Link>
                                    <Link to={'/register'} className="nav-link">Registrarse</Link>
                                </Nav>
                            </>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Row className="m-0 p-5 bg-success text-light">
                <Col className="text-center">
                    <h1>Bienvenido a Negrdo Distributions, nuestra plataforma de Software para Ventas e Inventario</h1>
                    <p className="lead">Optimiza tu negocio con nuestras soluciones innovadoras</p>
                    <Button variant="light">Explora nuestros servicios</Button>
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="text-center">
                    <Carousel data-bs-theme="dark" className="my-5" indicators={false}>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Gestión de Ventas</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Gestión de Rutas</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Gestión de Clientes</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Gestión de Productos y Categorias</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Gestión de Proveedores</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Gestión de Vendedores</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Gestión de Repartidores o entregadores</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Gestión de Asignación de Rutas a Vendedores</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <h1 className="animate__animated animate__pulse animate__infinite">Roles de Usuarios</h1>
                            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                        </Carousel.Item>
                    </Carousel>
                </Col>
                {/* <Col className="text-center">
                    <h2>Nuestros Servicios</h2>
                    <p className="lead">Ofrecemos una amplia gama de servicios para satisfacer tus necesidades empresariales:</p>
                    <ul className="list-unstyled">
                        <li>Software de Gestión de Inventarios</li>
                        <li>Plataforma de Punto de Venta (POS)</li>
                        <li>Sistema de Administración de Clientes</li>
                        <li>Análisis de Datos e Informes</li>
                        <li>Integraciones Personalizadas</li>
                    </ul>
                </Col> */}
            </Row>
            <Row className="m-0 p-5 bg-light">
                <Col className="text-center">
                    <h2>Síguenos en nuestras Redes Sociales</h2>
                    <div>
                        <a href="https://www.facebook.com/NegrdoWeb" target="_blank" style={{ color: '#3b5998' }}><i className="bi bi-facebook m-2"></i></a>
                        <a href="https://www.youtube.com/@negrdoproyecto6790" target="_blank" style={{ color: 'red' }}><i className="bi bi-youtube m-2"></i></a>
                        <a href="#" target="_blank" style={{ color: '#25D366' }}><i className="bi bi-whatsapp m-2"></i></a>
                    </div>
                </Col>
            </Row>
            <Row className="m-0 p-5 bg-light">
                <Col className="text-center">
                    <h2>Ponte en Contacto</h2>
                    <p className="lead">¿Interesado en nuestros servicios? ¡Contáctanos hoy mismo para obtener más información!</p>
                    <Button variant="primary">Contactar</Button>
                </Col>
            </Row>
        </>
    );
}
export default Welcome;