import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';

const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
`;

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SalesCreate = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);
    let { customerId } = useParams();

    const [customers, setCustomers] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [statu, setStatu] = useState([]);
    const [saleToday, setSaleToday] = useState([]);

    const timeZone = moment.tz.guess();

    const [createSale, setCreateSale] = useState({
        invoice: '',
        subTotal: 0,
        total: 0,
        inHand: 0,
        change: 0,
        iva: 0,
        discount: 0,
        business: {},
        customer: {},
        statu: {},
        seller: {},
        deliveryDate: moment().clone().tz(timeZone).valueOf(),
        observations: 'No hay observaciones',
        isVisible: true,
        details: [],
    });
    const [creatingSale, setCreatingSale] = useState(false);

    const [details, setDetails] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [customer, setCustomer] = useState({});
    const [invoice, setInvoice] = useState(uuidv4());
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [selectedSellerId, setSelectedSellerId] = useState('');
    const [defaultSellerId, setDefaultSellerId] = useState(null);

    const [selectedStatuId, setSelectedStatuId] = useState('');
    const [defaultStatuId, setDefaultStatuId] = useState(null);

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = products.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    );

    const columns = [
        // {
        //   name: 'Codigo',
        //   selector: row => row.name,
        //   sortable: true,
        // },
        // {
        //   name: 'Foto',
        //   selector: row => <><img src={`${apiGallery()}/images/${row?.business?.logo}`} alt={'logo'} style={{ width: '40px', height: '40px' }} /></>,
        //   sortable: true,
        // },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Unidad',
            selector: row => row.unit.name,
            sortable: true,
        },
        {
            name: 'Stock',
            selector: row => row.stock,
            sortable: true,
        },
        {
            name: 'Precio',
            selector: row => <>{row?.salePrice ? '$' + row.salePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} COP</>,
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => <>{row.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</>,
            sortable: true,
        },
        {
            name: 'Cantidad',
            selector: row => <><Form.Control type='number' size="sm" min='0' max={row.stock} className='mb-3' defaultValue={0} placeholder='Cant.' onChange={(e) => addToCart(row, parseInt(e.target.value))} /></>,
            sortable: false,
        },
    ];

    const handleShow = (id) => {
        //setDeleteId(id);
        setShow(true);
    }

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId !== '') {
            getCustomers();
            getSellers();
            getProducts();

            getStatus();
        }
        if (customerId !== 'new') {
            getCustomer();
        }
    }, [context, initialized, isPassed.businessId, customerId]);

    useEffect(() => {
        const temTotal = calculateTotal();
        if (temTotal !== createSale?.total) {
            setCreateSale((prevSale) => ({
                ...prevSale,
                invoice: invoice,
                subTotal: temTotal,
                total: temTotal,
                customer: createSale.customer,
                statu: createSale.statu,
                seller: createSale.seller,
                deliveryDate: createSale.deliveryDate,
                observations: createSale.observations,
                isVisible: createSale.isVisible,
                details: details
            }));
        }
    }, [createSale, details]);

    useEffect(() => {
        const storedStatuId = localStorage.getItem('defaultStatuId');
        if (storedStatuId) {
            setDefaultStatuId(storedStatuId);
            setSelectedStatuId(storedStatuId);
            setCreateSale({ ...createSale, statu: { id: storedStatuId } });
        }
    }, [selectedStatuId]);

    useEffect(() => {
        const storedSellerId = localStorage.getItem('defaultSellerId');
        if (storedSellerId) {
            setCreateSale({ ...createSale, seller: { id: storedSellerId } });
            setDefaultSellerId(storedSellerId);
            setSelectedSellerId(storedSellerId);
        }
    }, []);

    const destroyCart = () => {
        setDetails([]);
    }

    const addToCart = (product, quantity) => {
        const newQuantity = parseInt(quantity);
        if (newQuantity === 0) {
            return;
        }
        const newDetail = {
            invoice: invoice,
            quantity: newQuantity,
            price: product.salePrice,
            product: product,
            customer: createSale.customer,
        };
        const exists = details.findIndex(
            (detail) => detail.product?.id === product.id
        );
        setDetails((prevDetails) => {
            const updatedDetails = [...prevDetails];
            if (exists !== -1) {
                if (newQuantity === 0) {
                    updatedDetails.splice(exists, 1);
                } else {
                    updatedDetails[exists].quantity = newQuantity;
                }
            } else {
                if (newQuantity > 0) {
                    updatedDetails.push(newDetail);
                }
            }
            return updatedDetails;
        });
        const updatedTotal = calculateTotal();
        setCreateSale((prevSale) => ({
            ...prevSale,
            invoice: invoice,
            subTotal: updatedTotal,
            total: updatedTotal,
            customer: createSale.customer,
            seller: createSale.seller,
            statu: createSale.statu,
            deliveryDate: createSale.deliveryDate,
            details: exists !== -1 ? details : [...details, newDetail]
        }));
    }

    const removeFromCart = (productId) => {
        const updatedDetails = details.filter(detail => detail.product.id !== productId);
        setDetails(updatedDetails);
        const updatedTotal = calculateTotal();
        setCreateSale((prevSale) => ({
            ...prevSale,
            invoice: invoice,
            subTotal: updatedTotal,
            total: updatedTotal,
            customer: createSale.customer,
            seller: createSale.seller,
            statu: createSale.statu,
            deliveryDate: createSale.deliveryDate,
            details: updatedDetails
        }));
    }

    const getCustomers = async () => {
        await axios.get(`${apiRequest()}/customers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            const serverCustomers = res.data;
            const transformed = serverCustomers.map((customer) => ({
                value: customer.id,
                label: `${customer.owner} | ${customer.corporateName} | ${customer.phone} - ${customer.route.name}`,
                key: customer.id
            }));
            const withDefault = [
                { value: '', label: 'Selecciona un Cliente', key: 0 },
                ...transformed
            ];
            setCustomers(withDefault);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los clientes, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getStatus = async () => {
        await axios.get(`${apiRequest()}/status`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setStatu(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los estados, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getCustomer = async () => {
        await axios.get(`${apiRequest()}/customers/${customerId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setCustomer(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener el cliente, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getByCustomer = async (id) => {
        setSaleToday([]);
        if (id !== '') {
            await axios.post(`${apiRequest()}/sales/customers/${id}`, {}, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
                setSaleToday(res.data);
            }).catch((_err) => {
                toast.error({ render: "Error: No se pudo verificar el cliente, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            });
        }
    }

    const getProducts = async () => {
        await axios.get(`${apiRequest()}/products/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setProducts(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los productos, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getSellers = async () => {
        await axios.get(`${apiRequest()}/sellers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setSellers(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los vendedores, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const saveSale = async (event) => {
        event.preventDefault();
        if (details.length === 0 || createSale.customer === null || createSale.seller === null) {
            toast.error("Debe agregar al menos un producto", { position: "bottom-right", autoClose: 5000, closeOnClick: true });
            return;
        }
        const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
        setCreatingSale(true);
        await axios.post(`${apiRequest()}/sales/business/${isPassed.businessId}`, createSale, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            if (res.status === 200) {
                toast.update(toastId, { render: "Operación realizada con exito", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                setCreatingSale(false);
                destroyCart();
            }
        }).catch((_err) => {
            toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            setCreatingSale(false);
        });
    }

    const calculateTotal = () => {
        let total = 0;
        details.forEach((detail) => {
            total += detail.price * detail.quantity;
        });
        setSubTotal(total);
        setTotal(total);
        return total;
    }

    const handleChangeStatu = (e) => {
        const statusId = e.target.value;
        setCreateSale({ ...createSale, statu: { id: statusId } });
        setSelectedStatuId(statusId);
    }

    const handleSetDefaultStatu = () => {
        const newDefaultStatuId = defaultStatuId === selectedStatuId ? null : selectedStatuId;
        setDefaultSellerId(newDefaultStatuId);
        if (newDefaultStatuId) {
            localStorage.setItem('defaultStatuId', newDefaultStatuId);
        } else {
            localStorage.removeItem('defaultStatuId');
        }
        if (newDefaultStatuId !== null) {
            setCreateSale({ ...createSale, statu: { id: selectedStatuId } });
        }
    }

    const handleChangeSeller = (e) => {
        const sellerId = e.target.value;
        setCreateSale({ ...createSale, seller: { id: sellerId } });
        setSelectedSellerId(sellerId);
    }

    const handleSetDefaultSeller = () => {
        const newDefaultSellerId = defaultSellerId === selectedSellerId ? null : selectedSellerId;
        setDefaultSellerId(newDefaultSellerId);
        if (newDefaultSellerId) {
            localStorage.setItem('defaultSellerId', newDefaultSellerId);
        } else {
            localStorage.removeItem('defaultSellerId');
        }
        if (newDefaultSellerId !== null) {
            setCreateSale({ ...createSale, seller: { id: selectedSellerId } });
        }
    }

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <TextField
                id="search"
                type="text"
                placeholder="Buscar por Nombre"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
                autoFocus={true}
            />
            <ClearButton type="button" onClick={onClear}>
                X
            </ClearButton>
        </>
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <Container className='mt-3'>
            <Row className="mb-1">
                <div className="page-title">
                    <h3 className='text-center'>Pagina de facturación de Ventas</h3>
                    <p className="text-center text-muted">
                        ¡Bienvenido a nuestra plataforma de ventas!
                    </p>
                </div>
            </Row>
            <Form onSubmit={saveSale}>
                <Row className="mb-3">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Button variant="outline-danger" size="sm" onClick={destroyCart}><i className="bi bi-cart-fill"></i> CANCELAR VENTA ({createSale.details?.length})</Button>
                                <br />
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Vendedor</Form.Label>
                                        <Form.Select className="mb-3" size="sm" value={selectedSellerId} onChange={handleChangeSeller} required>
                                            <option value="">Selecciona un Vendedor</option>
                                            {sellers.map((seller, _index) => {
                                                return (
                                                    <option key={seller.id} value={seller.id}>{seller.people?.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        <Form.Check id="defaultSeller" style={{ fontSize: '12px' }} checked={defaultSellerId !== null && defaultSellerId === selectedSellerId} type="checkbox" label="Fijar vendedor como predeterminado?" onChange={handleSetDefaultSeller} />
                                    </Col>
                                    <Col>
                                        <Form.Label>Fecha</Form.Label>
                                        <Form.Control className="mb-3" size="sm" type="date" min={moment().clone().tz(timeZone).format('YYYY-MM-DD')} defaultValue={moment(createSale.deliveryDate).clone().tz(timeZone).format('YYYY-MM-DD')} onChange={(e) => setCreateSale({ ...createSale, deliveryDate: moment(e.target.value).clone().tz(timeZone).valueOf() })} required />
                                    </Col>
                                    <Col>
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Select className="mb-3" size="sm" value={selectedStatuId} onChange={handleChangeStatu} required>
                                            <option value="">Selecciona un Estado</option>
                                            {statu.map((statu, _index) => {
                                                return (
                                                    <option key={statu.id} value={statu.id}>{statu.name}</option>
                                                )
                                            })}
                                        </Form.Select>
                                        <Form.Check id="defaultStatu" style={{ fontSize: '12px' }} checked={defaultStatuId !== null && defaultStatuId === selectedStatuId} type="checkbox" label="Fijar estado como predeterminado?" onChange={handleSetDefaultStatu} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Cliente</Form.Label>
                                        <Select defaultValue={customers.find((customer) => customer.value === createSale?.customer?.id) || customers[0]} onChange={(e) => { setCreateSale({ ...createSale, customer: { id: e.value } }); getByCustomer(e.value) }} classNamePrefix={'select'} options={customers} className="mb-3" isSearchable={true} isClearable={false} required />
                                    </Col>
                                </Row>
                                {saleToday.length > 0 && (
                                    <Row>
                                        <Col>
                                            Este cliente tiene un factura creada de hoy puedes quitar o anexar haciendo clic {' '}
                                            <Link to={`/sales/${saleToday[0].id}/edit`}>Aquí</Link>
                                        </Col>
                                    </Row>
                                )}

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <Form.Label>Observaciones</Form.Label>
                                <Form.Control className="mb-3" size="sm" as="textarea" defaultValue={createSale.observations} onChange={(e) => setCreateSale({ ...createSale, observations: e.target.value })} />
                                <Form.Label>Total</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="number" value={total} readOnly />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="text-center">
                        <Button type="button" onClick={() => handleShow(createSale.id)} title="Acceder a los productos" className="btn-outline-success" size="lg" variant="light" disabled={saleToday.length > 0 || createSale.customer?.id === ''}><i className="bi bi-plus"></i> Productos</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped size='sm' responsive>
                            <thead className="table-success">
                                <tr>
                                    <th>#</th>
                                    <th>Nombre</th>
                                    <th>Unidad</th>
                                    <th>Precio</th>
                                    <th>Total</th>
                                    <th>Cantidad</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {createSale.details?.map((detail, index) => {
                                    return (
                                        <tr key={detail.product?.id}>
                                            <td>{index + 1}.</td>
                                            <td>{detail?.product?.name}</td>
                                            <td>{detail.product?.unit?.name}</td>
                                            <td>{detail.product?.salePrice ? '$' + detail.product?.salePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} COP</td>
                                            <td>{detail.product?.salePrice ? '$' + (detail.product?.salePrice * parseInt(createSale.details[index].quantity)).toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} COP</td>
                                            <td><Form.Control type='number' min='1' max={detail.product?.stock} className='mb-3' placeholder='Cant.' value={detail.quantity} onChange={(e) => addToCart(detail.product, e.target.value)} /></td>
                                            <td>
                                                <Button title="Remover de la factura" onClick={() => removeFromCart(detail.product.id)} className="btn-outline-danger" size="sm" variant="light"><i className="bi bi-trash"></i></Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Button variant="success" size="lg" type="submit" disabled={creatingSale || details.length === 0}>Facturar</Button>{' '}
                    </Col>
                </Row>
            </Form>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ventana de productos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataTable
                        title={`Total: ${total.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} COP`}
                        columns={columns}
                        data={filteredItems}
                        //progressPending={!loaded}
                        pagination={true}
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" title="Salir de la lista de productos" size="sm" onClick={handleClose}>
                        Salir
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </Container>
    );
}

export default SalesCreate;
