import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Placeholder, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { apiRequest } from '../../services/config';
import { StatusContext } from '../../contexts/status-context';
import moment from 'moment-timezone';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFSellers from '../reports/consolidations/sellers';

const CSellers = () => {

    const [context, setContext] = useContext(StatusContext);
    const { keycloak, initialized } = useKeycloak();
    const [sellers, setSellers] = useState([]);
    const [consolidate, setConsolidate] = useState([]);
    const timeZone = moment.tz.guess();
    const [loaded, setLoaded] = useState(true);
    let Total = 0;
    let Quantity = 0;
    const [request, setRequest] = useState({
        supplierId: '',
        date: moment().format('YYYY-MM-DD')
    });

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId) {
            getSellers();
        }
    }, [context, initialized, isPassed.businessId]);

    useEffect(() => {
        getConsolidationSeller();
    }, [request.supplierId, request.date]);

    const getSellers = async () => {
        await axios.get(`${apiRequest()}/sellers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            const server = res.data;
            const transformed = server.map((seller) => ({
                value: seller.id,
                label: `${seller.people.name} ${seller.people.lastName} | ${seller.phone}`,
                key: seller.id
            }));
            const withDefault = [
                { value: '', label: 'Selecciona un vendedor', key: 0 },
                ...transformed
            ];
            setSellers(withDefault);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los vendedores, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getConsolidationSeller = async () => {
        if (request.supplierId !== '' && request.date !== '') {
            setLoaded(false);
            setConsolidate([]);
            await axios.get(`${apiRequest()}/consolidations/sellers/business/${isPassed.businessId}/${request.supplierId}/${request.date}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
                setConsolidate(res.data);
                setLoaded(true);
            }).catch((_err) => {
                setLoaded(true);
                toast.error({ render: "Error: No se pudo obtener el consolidado, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            });
        }
    }

    return (
        <>
            <Container>
                <h1 className="text-center">Página de Consolidado de vendedores</h1>
                <Row className="mb-0">
                    <Col>
                        <Form.Label>Vendedor:</Form.Label>
                        <Select defaultValue={sellers[0]} onChange={(e) => { setRequest({ ...request, supplierId: e.value }); }} classNamePrefix={'select'} options={sellers} className="mb-3" isSearchable={true} isClearable={false} required />
                    </Col>
                    <Col>
                        <Form.Label>Fecha:</Form.Label>
                        <Form.Control type='date' max={moment().format('YYYY-MM-DD')} defaultValue={request.date} onChange={(e) => { setRequest({ ...request, date: moment(e.target.value).format('YYYY-MM-DD') }); }} className="mb-3" required />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className='text-center'>
                        <Button size="sm" type='button' variant='info' onClick={() => { }}><i className="bi bi-eye"></i> Visualizar</Button>{' '}
                        <PDFDownloadLink document={<PDFSellers data={consolidate} businessId={isPassed.businessId} />} fileName={`consolidado-vendeores-${moment().tz(timeZone).format('DD-MM-YYYY')}.pdf`}>
                            {({ blob, url, loading, error }) =>
                                <Button size="sm" type='button' variant='danger' onClick={() => { }}><i className="bi bi-download"></i> {loading ? 'Cargando documento...' : 'Descargar'}</Button>
                            }
                        </PDFDownloadLink>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(consolidate.length === 0 && !loaded) ? (
                            <>
                                <Placeholder as={Row} size="xs" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} size="sm" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} size="lg" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                            </>
                        ) : (
                            <>
                                <Table style={{ fontSize: '15px' }} size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th className="text-success">Codigo</th>
                                            <th className="text-success">Descripción</th>
                                            <th className="text-success">Cantidad</th>
                                            <th className="text-success">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {consolidate.map((cons, _index) => {
                                            Total += cons.Total;
                                            Quantity += cons.Quantity;
                                            return (
                                                <tr key={cons.id}>
                                                    <td>{cons.code}</td>
                                                    <td>{cons.name}</td>
                                                    <td>{cons.Quantity}</td>
                                                    <td>{cons.Total.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan={2}></td>
                                            <td className="text-success">{Quantity}</td>
                                            <td className="text-success">{Total.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </>
                        )}

                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default CSellers;