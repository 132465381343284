import React, { useContext, useEffect, useMemo, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { StatusContext } from "../../contexts/status-context";
import { apiEvents, apiRequest } from "../../services/config";
/**import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";*/
import ManagerDashboard from "../dashboards/manager-dashboard";
import SellerDashboard from "../dashboards/seller-dashboard";
import DelivererDashboard from "../dashboards/deliverer-dashboard";
import PackerDashboard from "../dashboards/packer-dashboard";

const Overview = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);
    const [eventSource, setEventSource] = useState(null);
    const [events, setEvents] = useState([]);

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    const options = {

        title: {
            text: 'U.S Solar Employment Growth',
            align: 'left'
        },

        subtitle: {
            text: 'By Job Category. Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>.',
            align: 'left'
        },

        yAxis: {
            title: {
                text: 'Number of Employees'
            }
        },

        xAxis: {
            accessibility: {
                rangeDescription: 'Range: 2010 to 2020'
            }
        },

        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },

        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: 2010
            }
        },

        series: [{
            name: 'Installation & Developers',
            data: [43934, 48656, 65165, 81827, 112143, 142383,
                171533, 165174, 155157, 161454, 154610]
        }, {
            name: 'Manufacturing',
            data: [24916, 37941, 29742, 29851, 32490, 30282,
                38121, 36885, 33726, 34243, 31050]
        }, {
            name: 'Sales & Distribution',
            data: [11744, 30000, 16005, 19771, 20185, 24377,
                32147, 30912, 29243, 29213, 25663]
        }, {
            name: 'Operations & Maintenance',
            data: [null, null, null, null, null, null, null,
                null, 11164, 11218, 10077]
        }, {
            name: 'Other',
            data: [21908, 5548, 8105, 11248, 8989, 11816, 18274,
                17300, 13053, 11906, 10073]
        }],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }

    }

    useEffect(() => {
        if (initialized && isPassed.businessId !== '') {
            console.log('Running...');
            const newEvent = new EventSource(`${apiEvents()}/events/distributions/sales`, { withCredentials: true });

            setEventSource(newEvent);

            newEvent.onopen = (event) => {
                console.log(event.isTrusted);
            }
            newEvent.onerror = () => {
                console.log('SERVER EVENT ERROR');
            }
            newEvent.onmessage = (message) => {
                if (message.isTrusted) {
                    const event = JSON.parse(message.data);
                    setEvents(prev => [event, ...prev]);
                    console.log('Ready');
                } else {
                    console.log('NOT TRUSTED');
                }
            }
            return () => newEvent.close();
        }
    }, [context, initialized, setEventSource]);

    return (
        <Container fluid>
            {/** Dashboard Title */}
            <Row className="m-1">
                <Col>
                    <h5 className="float-start">Dashboard, Hola {keycloak.tokenParsed?.given_name}</h5>
                </Col>
                <Col>
                    <h5 className="float-end">{keycloak.tokenParsed?.resource_access['negrdo-distributions-web']?.roles[0]?.toLocaleUpperCase()}</h5>
                </Col>
            </Row>
            {keycloak.hasResourceRole('manager') && (
                <ManagerDashboard />
            )}
            {keycloak.hasResourceRole('seller') && (
                <SellerDashboard />
            )}
            {keycloak.hasResourceRole('deliverer') && (
                <DelivererDashboard />
            )}
            {keycloak.hasResourceRole('packer') && (
                <PackerDashboard />
            )}
        </Container>
    );
}
export default Overview;