import { useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Card, Col, Container, Placeholder, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';

const Payments = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);

    const [users, setPayments] = useState([]);
    const [loaded, setLoaded] = useState(true);

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId) {
            getPayments();
        }
    }, [context, initialized, isPassed.businessId]);

    const getPayments = async () => {
        setLoaded(false);
        await axios.get(`${apiRequest()}/payments/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setPayments(res.data);
            setLoaded(true);
        }).catch((_err) => {
            setLoaded(true);
            toast.error({ render: "Error: No se pudo obtener los pagos, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    return (
        <Container className='mt-5'>
            <Row>
                <Col xs={12}>
                    <div className="page-title">
                        <h3 className='text-center'>
                            Mis pagos
                            {' '}<Badge pill bg="primary" className="animate__animated animate__pulse animate__infinite" style={{ fontSize: '10px' }}>Pronto</Badge>
                            <span className="visually-hidden">unread messages</span>
                        </h3>
                        <p className="text-center text-muted">
                            ¡Bienvenido a nuestra plataforma de pagos! En esta sección, podrás explorar la lista completa de pagos asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de pagos.
                            <br />¡Que disfrutes explorando la lista de pagos!
                            <br />
                        </p>
                    </div>
                    <Card>
                        <Card.Body>
                            {(users.length === 0 && !loaded) ? (
                                <>
                                    <Placeholder as={Row} size="xs" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} size="sm" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} size="lg" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                </>
                            ) : (
                                <>
                                    <Table striped size='sm' responsive>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Fecha de pago</th>
                                                <th>Fecha Pagado</th>
                                                <th>Monto</th>
                                                <th>Status</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((payment, index) => {
                                                return (
                                                    <tr key={payment.id}>
                                                        <td>{index + 1}.</td>
                                                        <td>{payment.datePay}</td>
                                                        <td>{payment.datePaid}</td>
                                                        <td>{payment.amount}</td>
                                                        <td>{payment.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</td>
                                                        <td>
                                                            ---
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
}

export default Payments;
