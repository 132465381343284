import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Placeholder, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { apiRequest } from '../../services/config';
import { StatusContext } from '../../contexts/status-context';
import moment from 'moment-timezone';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFBusiness from '../reports/consolidations/business';

const CBusiness = () => {

    const [context, setContext] = useContext(StatusContext);
    const { keycloak, initialized } = useKeycloak();
    const [consolidate, setConsolidate] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const timeZone = moment.tz.guess();
    const [request, setRequest] = useState({
        date: moment().tz(timeZone).format('YYYY-MM-DD')
    });

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId && request.date) {
            getConsolidationBusiness();
        }
    }, [context, initialized, isPassed.businessId, request.date]);

    const getConsolidationBusiness = async () => {
        if (request.date !== '') {
            setLoaded(false);
            setConsolidate([]);
            await axios.get(`${apiRequest()}/consolidations/business/${isPassed.businessId}/${request.date}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
                setConsolidate(res.data);
                setLoaded(true);
            }).catch((_err) => {
                setLoaded(true);
                toast.error({ render: "Error: No se pudo obtener el consolidado, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            });
        }
    }

    return (
        <>
            <Container>
                <h1 className="text-center">Página de Consolidado de Empresa</h1>
                <Row className="mb-0">
                    <Col>
                        <Form.Label>Fecha:</Form.Label>
                        <Form.Control size="sm" type='date' max={moment().tz(timeZone).format('YYYY-MM-DD')} defaultValue={request.date} onChange={(e) => { setRequest({ ...request, date: moment(e.target.value).tz(timeZone).format('YYYY-MM-DD') }); }} className="mb-3" required />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className='text-center'>
                        <Button size="sm" type='button' variant='info' onClick={() => { }}><i className="bi bi-eye"></i> Visualizar</Button>{' '}
                        <PDFDownloadLink document={<PDFBusiness data={consolidate} businessId={isPassed.businessId} />} fileName={`consolidado-empresa${moment().tz(timeZone).format('DD-MM-YYYY')}.pdf`}>
                            {({ blob, url, loading, error }) =>
                                <Button size="sm" type='button' variant='danger' onClick={() => { }}><i className="bi bi-download"></i> {loading ? 'Cargando documento...' : 'Descargar'}</Button>
                            }
                        </PDFDownloadLink>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(consolidate.length === 0 && !loaded) ? (
                            <>
                                <Placeholder as={Row} size="xs" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} size="sm" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} size="lg" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                            </>
                        ) : (
                            <>
                                <Table style={{ fontSize: '15px' }} size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th className="text-success">Codigo</th>
                                            <th className="text-success">Descripción</th>
                                            <th className="text-success">Cantidad</th>
                                            <th className="text-success">P. Compra</th>
                                            <th className="text-success">P. Venta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {consolidate.map((cons, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{cons.code}</td>
                                                    <td>{cons.description}</td>
                                                    <td>{cons.Quantity}</td>
                                                    <td>{cons.TotalCompra.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>{cons.TotalVenta.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>    
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </>
                        )}

                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default CBusiness;