import React, { Fragment, useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, PDFViewer } from '@react-pdf/renderer';
import { useKeycloak } from '@react-keycloak/web';
import { apiGallery, apiRequest } from '../../services/config';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 40,
    },
    header: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    logoContainer: {
        width: '12%',
        textAlign: 'left',
    },
    logo: {
        width: 85,
    },
    companyNameContainer: {
        fontSize: '9px',
        width: '75%',
        textAlign: 'center',
    },
    companyName: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    companyDetails: {
        fontSize: 11,
        marginTop: 5,
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        borderTop: '1 solid #C1CED9',
        padding: 8,
        color: '#5D6975',
        fontSize: 10,
    },
    thead: {
        backgroundColor: 'orange',
        fontSize: 9,
        paddingTop: 4,
        paddingLeft: 7,
        flex: 1,
        borderColor: 'whitesmoke',
        borderRightWidth: 1,
        borderBottomWidth: 1
    },
    tbody: {
        fontSize: 7,
        paddingTop: 4,
        paddingLeft: 7,
        flex: 1,
        borderColor: 'whitesmoke',
        borderRightWidth: 1,
        borderBottomWidth: 1
    },
});

const Invoice = () => {

    const { keycloak, initialized } = useKeycloak();
    const [sale, setSale] = useState({});
    let { invoice } = useParams();

    useEffect(() => {
        if (initialized) {
            getSale(invoice);
        }
    }, [initialized]);

    const getSale = (id) => {
        axios.get(`${apiRequest()}/sales/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setSale(res.data);
        }).catch((err) => {
            console.error('error', err);
        });
    }

    return (
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
            <Document>
                <Page size="A4" style={styles.page}>
                    {/* Encabezado */}
                    <View style={styles.header}>
                        <View style={styles.logoContainer}>
                            <Image src={`${apiGallery()}/images/${sale?.business?.logo ? sale?.business?.logo : 'logoNegrdo.png'}`} style={styles.logo} />
                        </View>
                        <View style={styles.companyNameContainer}>
                            <Text style={styles.companyName}>{sale?.business?.name}</Text>
                            <Text style={styles.companyDetails}>
                                Dirección: {sale?.business?.address} {"\n"}
                                Telefonos: {sale?.business?.phone} {"\n"}
                                N.i.t: {sale?.business?.nit} {"\n"}
                                Correo: {sale?.business?.email} {"\n"}
                                Regimen simplificado
                            </Text>
                        </View>
                    </View>

                    {/* Cuerpo del documento */}
                    <View>
                        <View style={{ width: '100%', flexDirection: 'row' }}>
                            {/* Detalles del cliente */}
                            <View style={{ padding: '0px', float: 'left', width: '50%', textAlign: 'left' }}>
                                <View style={{ fontSize: '9px' }}>
                                    <Text>COD: {sale?.customer?.code}</Text>
                                    <Text>SEÑORES: {sale.customer?.owner}</Text>
                                    <Text>RAZON SOCIAL: {sale.customer?.corporateName}</Text>
                                    <Text>DIRECCIÓN: {sale.customer?.address}</Text>
                                    <Text>ELABORADO POR: OFICINA</Text>
                                </View>
                            </View>

                            {/* Detalles de la venta */}
                            <View style={{ padding: '0px', float: 'left', width: '50%', textAlign: 'right' }}>
                                <View style={{ fontSize: '9px' }}>
                                    <Text>NIT: {sale.customer?.code}</Text>
                                    <Text>TELEFONO: {sale.customer?.phone}</Text>
                                    <Text>FECHA: {moment(sale.created_at).format('DD-MM-YYYY')}</Text>
                                    <Text>VENDEDOR: {sale.seller?.people?.name}</Text>
                                    <Text>FACTURA: {sale.invoice} ({sale.customer?.route?.name})</Text>
                                </View>
                            </View>
                        </View>

                        <View>
                            <View>
                                <View style={{ width: '100%', flexDirection: 'row' }}>
                                    <View style={styles.thead}>
                                        <Text>CODIGO</Text>
                                    </View>
                                    <View style={styles.thead}>
                                        <Text>DESCRIPCION</Text>
                                    </View>
                                    <View style={styles.thead}>
                                        <Text style={{ textAlign: 'center' }}>UNIDAD</Text>
                                    </View>
                                    <View style={styles.thead}>
                                        <Text style={{ textAlign: 'center' }}>CANTIDAD</Text>
                                    </View>
                                    <View style={styles.thead}>
                                        <Text style={{ textAlign: 'right' }}>VR. UNIT</Text>
                                    </View>
                                    <View style={styles.thead}>
                                        <Text style={{ textAlign: 'right' }}>SUBTOTAL</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* Detalles de la venta */}
                        <View style={{ fontSize: '9px' }}>
                            {sale.details?.map((detail) => (
                                <View key={detail.id}>
                                    <View style={{ width: '100%', flexDirection: 'row' }}>
                                        <View style={[styles.tbody]}>
                                            <Text >{detail.product?.code}</Text>
                                        </View>
                                        <View style={styles.tbody}>
                                            <Text>{detail.product?.name} </Text>
                                        </View>
                                        <View style={styles.tbody}>
                                            <Text style={{ textAlign: 'center' }}>{detail.product?.unit?.name} </Text>
                                        </View>
                                        <View style={styles.tbody}>
                                            <Text style={{ textAlign: 'center' }}>{detail.quantity}</Text>
                                        </View>
                                        <View style={styles.tbody}>
                                            <Text style={{ textAlign: 'right' }}>{detail.price}</Text>
                                        </View>
                                        <View style={styles.tbody}>
                                            <Text style={{ textAlign: 'right' }}>{(detail.price * detail.quantity) ? (detail.price * detail.quantity).toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</Text>
                                        </View>
                                    </View>
                                </View>
                            ))}
                        </View>

                        <View style={{ width: '100%', flexDirection: 'row' }}>
                            {/* Observaciones */}
                            <View style={{ width: '50%', textAlign: 'left', marginTop: '15px' }}>
                                <Text style={{ color: 'red', fontSize: '9px' }}>OBSERVACIONES:</Text>
                                <Text style={{ fontSize: '9px' }}>{sale.observations}</Text>
                            </View>

                            {/* Totales */}
                            <View style={{ width: '50%', textAlign: 'right', fontSize: '9px', marginTop: '15px' }}>
                                <Text>SUB-TOTAL $ {sale.subTotal}</Text>
                                <Text>DESCUENTO $ {sale.discount}</Text>
                                <Text>IVA $ {sale.iva}</Text>
                                <Text>TOTAL-FACTURA {sale.total ? '$' + (sale.total).toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</Text>
                            </View>
                        </View>
                    </View>

                    {/* Nota */}
                    <Text style={{ color: 'black', marginTop: 10, fontSize: '9px' }}>
                        NOTA: La presente factura de venta se asimila en todos los efectos legales a una letra de cambio según artículo 774 del código de comercio.
                    </Text>

                    {/* Pie de página */}
                    <View style={styles.footer}>
                        <Text>Negrdo © {new Date().getFullYear()} Todos los derechos reservados.</Text>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
}
export default Invoice;