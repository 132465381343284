export function apiRequest() {
    return 'https://ds.negrdo.com';
};

export function getIdentity() {
    return 'https://auth.negrdo.com';
};

export function getRealm() {
    return 'negrdo';
};

export function getClient() {
    return 'negrdo-distributions-web';
}

export function apiEvents() {
    return 'https://events.negrdo.com';
};

export function apiGallery() {
    return 'https://gallery.negrdo.com/api';
};