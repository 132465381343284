import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import IdentityProvider from './services/identity-provider';
import StatusProvider from './contexts/status-context';
import { Route, Routes as LinkRutas } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Layout from './components/public/layout';
import Customers from './components/customers/customers';
import Products from './components/products/products';
import Overview from './components/overview/overview';
import SalesCreate from './components/sales/create';
import Deliverers from './components/deliverers/deliverers';
import Users from './components/users/users';
import Invoice from './components/reports/invoice';
import Suppliers from './components/suppliers/suppliers';
import Sales from './components/sales/sales';
import Sellers from './components/sellers/sellers';
import Packers from './components/packers/packers';
import Welcome from './components/public/welcome';
import Register from './components/public/register';
import Routes from './components/routes/routes';
import SalesEdit from './components/sales/edit';
import Assignments from './components/assignments/assignments';
import Account from './components/account/account';
import CSuppliers from './components/consolidations/suppliers';
import CSellers from './components/consolidations/sellers';
import Assorts from './components/assorts/assorts';
import CCustomers from './components/consolidations/customers';
import CGeneral from './components/consolidations/general';
import CBusiness from './components/consolidations/business';
import Payments from './components/payments/payments';
import Assigned from './components/customs/sellers/assignments-seller';
import SSeller from './components/customs/sellers/sales-sellers';
import CUSTOMCustomers from './components/customs/sellers/customers-seller';
import CUSTOMProducts from './components/customs/sellers/products-seller';

const LayoutRoutes = () => {

  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', minHeight: '800px', alignItems: 'center' }}>
        <h2 style={{ textAlign: 'center' }}>
          <Spinner animation="border" variant="success" />{" "}
          Loading...
        </h2>
      </div>
    );
  }

  return (
    <LinkRutas>
      {keycloak.authenticated && (
        <>
          <Route path='/' element={<Layout />}>
            <Route index element={<Overview />} />
            {(keycloak.hasResourceRole('seller')) && (
              <>
                <Route path="/products" element={<CUSTOMProducts />} />
                <Route path="/customers" element={<CUSTOMCustomers />} />
                <Route path="/assigned" element={<Assigned />} />
                <Route path="/sales" element={<SSeller />} />
                <Route path="/sales/:customerId/create" element={<SalesCreate />} />
                <Route path="/sales/:id/edit" element={<SalesEdit />} />
              </>
            )}

            {keycloak.hasResourceRole('manager') && (
              <>
                <Route path="/products" element={<Products />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="/sales/:customerId/create" element={<SalesCreate />} />
                <Route path="/sales/:id/edit" element={<SalesEdit />} />
                <Route path="/suppliers" element={<Suppliers />} />
                <Route path="/routes" element={<Routes />} />
                <Route path="/sellers" element={<Sellers />} />
                <Route path="/packers" element={<Packers />} />
                <Route path="/deliverers" element={<Deliverers />} />
                <Route path="/users" element={<Users />} />
                <Route path="/assignments" element={<Assignments />} />
                <Route path="/account" element={<Account />} />
                <Route path="/assorts" element={<Assorts />} />
                <Route path="/payments" element={<Payments />} />

                {/** Consolidations */}
                <Route path="/consolidations/general" element={<CGeneral />} />
                <Route path="/consolidations/suppliers" element={<CSuppliers />} />
                <Route path="/consolidations/sellers" element={<CSellers />} />
                <Route path="/consolidations/customers" element={<CCustomers />} />
                <Route path="/consolidations/business" element={<CBusiness />} />
              </>
            )}
          </Route>
          <Route path="/reports/invoice/:invoice" element={<Invoice />} />

        </>
      )}
      <>
        <Route path="/" element={<Welcome />} />
        <Route path="/register" element={<Register />} />
      </>
    </LinkRutas>
  );
}

function App() {
  return (
    <ReactKeycloakProvider authClient={IdentityProvider}>
      <StatusProvider>
        <LayoutRoutes />
      </StatusProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
