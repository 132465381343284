import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';

const Assignments = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);

    const [sellers, setSellers] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [days, setDays] = useState([]);

    const [assignments, setAssignments] = useState([]);

    const [createAssignment, setCreateAssignment] = useState({
        route: {},
        seller: {},
        day: {},
        status: false
    });
    const [create, setCreate] = useState(false);
    const handleCloseCreate = () => setCreate(false);
    const handleShowCreate = () => setCreate(true);
    const [creating, setCreating] = useState(false);

    const [updateAssignment, setUpdateAssignment] = useState({});
    const [update, setUpdate] = useState(false);
    const handleCloseUpdate = () => setUpdate(false);
    const handleShowUpdate = () => setUpdate(true);
    const [updating, setUpdating] = useState(false);

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId) {
            getAssignments();
            getDays();
            getSellers();
            getRoutes();
        }
    }, [context, initialized, isPassed.businessId]);

    const getAssignments = async () => {
        await axios.get(`${apiRequest()}/assignments/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setAssignments(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener las asignaciones, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getSellers = async () => {
        await axios.get(`${apiRequest()}/sellers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            const serverSellers = res.data;
            const transformed = serverSellers.map((seller) => ({
                value: seller.id,
                label: `${seller.people?.name} ${seller.people?.lastName} | ${seller.phone}`,
                key: seller.id
            }));
            const withDefault = [
                { value: '', label: 'Selecciona un vendedor', key: 0 },
                ...transformed
            ];
            setSellers(withDefault);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los vendedores, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getDays = async () => {
        await axios.get(`${apiRequest()}/days`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            const serverDays = res.data;
            const transformed = serverDays.map((day) => ({
                value: day.id,
                label: day.name,
                key: day.id
            }));
            const withDefault = [
                { value: '', label: 'Selecciona un dia', key: 0 },
                ...transformed
            ];
            setDays(withDefault);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los días, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getRoutes = async () => {
        await axios.get(`${apiRequest()}/routes/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            const serverRoutes = res.data;
            const transformed = serverRoutes.map((route) => ({
                value: route.id,
                label: route.name,
                key: route.id
            }));
            const withDefault = [
                { value: '', label: 'Selecciona una ruta', key: 0 },
                ...transformed
            ];
            setRoutes(withDefault);
        }).catch((err) => {
            toast.error({ render: "Error: No se pudo obtener las rutas, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getAssignment = async (id) => {
        handleShowUpdate();
        setUpdateAssignment({});
        await axios.get(`${apiRequest()}/assignments/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setUpdateAssignment(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener la asignación, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const saveAssignment = async (event) => {
        event.preventDefault();
        setUpdating(true);
        const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
        setCreating(true);
        await axios.post(`${apiRequest()}/assignments/business/${isPassed.businessId}`, createAssignment, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            if (res.status === 200) {
                setCreateAssignment({});
                event.target.reset();
                handleCloseCreate();
                toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                getAssignments();
                setCreating(false);
            }
        }).catch((_err) => {
            setCreating(false);
            toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const saveChanges = async (event) => {
        event.preventDefault();
        setUpdating(true);
        const toastId = toast.loading("Guardando cambios, por favor espere...", { hideProgressBar: false, position: "bottom-center" });
        await axios.put(`${apiRequest()}/assignments/${updateAssignment.id}`, updateAssignment, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            if (res.status === 200) {
                setUpdateAssignment({});
                handleCloseUpdate();
                toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                setUpdating(false);
                getAssignments();
            }
        }).catch((_err) => {
            setUpdating(false);
            toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    return (
        <Container className='mt-5'>
            <Row>
                <Col xs={12}>
                    <div className="page-title">
                        <h3 className='text-center'>Asignación de rutas</h3>
                        <p className="text-center text-muted">
                            ¡Bienvenido a nuestra plataforma de asignación! En esta sección, podrás explorar la lista completa de asignación de rutas asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de asignación de rutas.
                            <br />¡Que disfrutes explorando la lista de asignación de rutas!
                            <br />
                            {keycloak.hasResourceRole('manager') && (
                                <>
                                    <Button variant="success" size="sm" onClick={handleShowCreate}>Asignar nueva ruta</Button>{' '}
                                </>
                            )}
                        </p>
                    </div>
                    <Card>
                        <Card.Body>
                            <Table striped size='sm'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Dia</th>
                                        <th>Ruta</th>
                                        <th>Vendedor</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assignments.map((assignment, index) => {
                                        return (
                                            <tr key={assignment.id}>
                                                <td>{index + 1}.</td>
                                                <td>{assignment.day?.name}</td>
                                                <td>{assignment.route?.name}</td>
                                                <td>{assignment.seller?.people?.name} {assignment.seller?.people?.lastName}</td>
                                                <td>{assignment.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</td>
                                                <td>
                                                    <Button title="Ver detalles" className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>
                                                    {' '}
                                                    {keycloak.hasResourceRole('manager') && (
                                                        <Button onClick={() => getAssignment(assignment.id)} title="Editar datos de vendedor" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Offcanvas placement="end" show={create} onHide={handleCloseCreate} backdrop="static">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Nueva asignación de ruta</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    En este espacio, podrás realizar cambios y actualizaciones en la información de tus rutas de manera rápida y sencilla. Mantener la información de tus rutas al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
                    <br />
                    <br />
                    <Form onSubmit={saveAssignment}>
                        <Form.Label>Ruta</Form.Label>
                        <Select defaultValue={routes.find((route) => route.value === createAssignment?.route?.id) || routes[0]} onChange={(e) => setCreateAssignment({ ...createAssignment, route: { id: e.value } })} classNamePrefix={'select'} options={routes} className="mb-3" isSearchable={true} isClearable={false} required />
                        <Form.Label>Vendedor</Form.Label>
                        <Select defaultValue={sellers.find((seller) => seller.value === createAssignment?.seller?.id) || sellers[0]} onChange={(e) => setCreateAssignment({ ...createAssignment, seller: { id: e.value } })} classNamePrefix={'select'} options={sellers} className="mb-3" isSearchable={true} isClearable={false} required />
                        <Form.Label>Dia</Form.Label>
                        <Select defaultValue={days.find((day) => day.value === createAssignment?.day?.id) || days[0]} onChange={(e) => setCreateAssignment({ ...createAssignment, day: { id: e.value } })} classNamePrefix={'select'} options={days} className="mb-3" isSearchable={true} isClearable={false} required />
                        <Form.Label>Estado</Form.Label>
                        <Form.Switch checked={createAssignment.status || false} value={createAssignment.status} onChange={(e) => setCreateAssignment({ ...createAssignment, status: e.target.checked })} />
                        <br />
                        <Button variant="success" size="sm" type="submit" disabled={creating}>{creating ? 'Guardando...' : 'Guardar'}</Button>{' '}
                        <Button variant="secondary" size="sm" onClick={handleCloseCreate}>Cancelar</Button>{' '}
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas placement="end" show={update} onHide={handleCloseUpdate} backdrop="static">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Actualizar asignación de rutas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    En este espacio, podrás realizar cambios y actualizaciones en la información de tus rutas de manera rápida y sencilla. Mantener la información de tus rutas al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
                    <br />
                    <br />
                    <Form onSubmit={saveChanges}>
                        <Form.Label>Ruta</Form.Label>
                        <Select value={routes.find((route) => route.value === updateAssignment.route?.id)} onChange={(e) => setUpdateAssignment({ ...createAssignment, route: { id: e.value } })} classNamePrefix={'select'} options={routes} className="mb-3" isSearchable={true} isClearable={false} required />
                        <Form.Label>Vendedor</Form.Label>
                        <Select value={sellers.find((seller) => seller.value === updateAssignment.seller?.id)} onChange={(e) => setUpdateAssignment({ ...updateAssignment, seller: { id: e.value } })} classNamePrefix={'select'} options={sellers} className="mb-3" isSearchable={true} isClearable={false} required />
                        <Form.Label>Dia</Form.Label>
                        <Select value={days.find((day) => day.value === updateAssignment.day?.id)} onChange={(e) => setUpdateAssignment({ ...updateAssignment, day: { id: e.value } })} classNamePrefix={'select'} options={days} className="mb-3" isSearchable={true} isClearable={false} required />
                        <Form.Label>Estado</Form.Label>
                        <Form.Switch checked={updateAssignment.status || false} value={updateAssignment.status} onChange={(e) => setUpdateAssignment({ ...updateAssignment, status: e.target.checked })} />
                        <br />
                        <Button variant="success" size="sm" type="submit" disabled={updating}>{updating ? 'Guardando cambios...' : 'Guardar cambios'}</Button>{' '}
                        <Button variant="secondary" size="sm" onClick={handleCloseUpdate}>Cancelar</Button>{' '}
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />
        </Container>
    );
}

export default Assignments;
