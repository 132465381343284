import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';

const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
`;

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SalesEdit = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);
    let { id } = useParams();
    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [statu, setStatu] = useState([]);

    const timeZone = moment.tz.guess();

    const [editSale, setEditSale] = useState({
        invoice: '',
        subTotal: 0,
        total: 0,
        inHand: 0,
        change: 0,
        iva: 0,
        discount: 0,
        business: {},
        customer: {},
        statu: {},
        seller: {},
        deliveryDate: 0,
        observations: '',
        isVisible: false,
        details: [],
    });
    const [updatingSale, setUpdatingSale] = useState(false);

    const [details, setDetails] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [customer, setCustomer] = useState({});
    const [invoice, setInvoice] = useState(uuidv4());
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = products.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
        || item.unit?.name && item.unit?.name.toLowerCase().includes(filterText.toLowerCase()),
    );

    const columns = [
        // {
        //   name: 'Codigo',
        //   selector: row => row.name,
        //   sortable: true,
        // },
        // {
        //   name: 'Foto',
        //   selector: row => <><img src={`${apiGallery()}/images/${row?.business?.logo}`} alt={'logo'} style={{ width: '40px', height: '40px' }} /></>,
        //   sortable: true,
        // },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Unidad',
            selector: row => row.unit.name,
            sortable: true,
        },
        {
            name: 'Stock',
            selector: row => row.stock,
            sortable: true,
        },
        // {
        //     name: 'Total',
        //     selector: row => row.stock,
        //     sortable: true,
        // },
        {
            name: 'Precio',
            selector: row => <>{row?.salePrice ? '$' + row.salePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} COP</>,
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => <>{row.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</>,
            sortable: true,
        },
        {
            name: 'Cantidad',
            selector: row => <><Form.Control type='number' size="sm" min='1' max={row.stock} className='mb-3' defaultValue={0} placeholder='Cant.' onChange={(e) => setQuantity(e.target.value)} /></>,
            sortable: false,
        },
        {
            name: 'Acciones',
            selector: row => <><Button title="Agregar a la factura" onClick={() => addToCart(row, quantity)} className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-cart-plus"></i></Button></>,
            sortable: false,
        },
    ];

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        //setDeleteId(id);
        setShow(true);
    }

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId) {
            getProducts();
            if (id !== '') {
                getSale();
            }
            getStatus();
        }
    }, [context, initialized, isPassed.businessId]);

    useEffect(() => {
        const temTotal = calculateTotal();
        if (temTotal !== editSale?.total) {
            setEditSale((prevSale) => ({
                ...prevSale,
                customer: editSale.customer,
                invoice: editSale.invoice,
                subTotal: temTotal,
                total: temTotal,
                details: details
            }));
        }
    }, [editSale, details]);

    const getProductByName = async (filter) => {
        await axios.get(`${apiRequest()}/products/business/${isPassed.businessId}/${filter}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setFilteredProducts(res.data);
            setProducts(res.data);
        }).catch((err) => {
            console.error('error', err);
        });
    }

    const getSale = async () => {
        await axios.get(`${apiRequest()}/sales/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setEditSale(res.data);
            setDetails(res.data.details);
            setCustomer(res.data.customer);
            setInvoice(res.data.invoice);
        }).catch((err) => {
            console.error('error', err);
        });
    }

    const destroyCart = () => {
        setDetails([]);
        setEditSale({});
    }

    const addToCart = (product, quantity) => {
        const newDetail = {
            invoice: invoice,
            customer: customer,
            product: product,
            quantity: parseInt(quantity),
            price: product.salePrice
        };
        const exists = details.findIndex(
            (detail) => detail.product?.id === product.id
        );
        setDetails((prevDetails) => {
            const updatedDetails = [...prevDetails];
            if (exists !== -1) {
                updatedDetails[exists].quantity = parseInt(quantity);
            } else {
                updatedDetails.push(newDetail);
            }
            return updatedDetails;
        });
        const updatedTotal = calculateTotal();
        setEditSale((prevSale) => ({
            ...prevSale,
            invoice: invoice,
            subTotal: updatedTotal,
            total: updatedTotal,
            customer: customer,
            details: exists !== -1 ? details : [...details, newDetail]
        }));
    }

    const removeFromCart = (productId) => {
        const updatedDetails = details.filter(detail => detail.product.id !== productId);
        setDetails(updatedDetails);
        const updatedTotal = calculateTotal();
        setEditSale((prevSale) => ({
            ...prevSale,
            subTotal: updatedTotal,
            total: updatedTotal,
            details: updatedDetails
        }));
    }

    const getStatus = async () => {
        await axios.get(`${apiRequest()}/status`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setStatu(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los estados, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getProducts = async () => {
        await axios.get(`${apiRequest()}/products/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setProducts(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los productos, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const updateSale = async (event) => {
        event.preventDefault();
        const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
        setUpdatingSale(true);
        await axios.put(`${apiRequest()}/sales/${id}`, editSale, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            if (res.status === 200) {
                toast.update(toastId, { render: "Operación realizada con exito", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                event.target.reset();
                setUpdatingSale(false);
                destroyCart();
                navigate('/sales');
            }
        }).catch((_err) => {
            toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            setUpdatingSale(false);
        });
    }

    const calculateTotal = () => {
        let total = 0;
        details.forEach((detail) => {
            total += detail.price * detail.quantity;
        });
        setSubTotal(total);
        setTotal(total);
        return total;
    }

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <TextField
                id="search"
                type="text"
                placeholder="Buscar por Nombre"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
                autoFocus={true}
            />
            <ClearButton type="button" onClick={onClear}>
                X
            </ClearButton>
        </>
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <Container className='mt-5'>
            <Row>
                <div className="page-title">
                    <h3 className='text-center'>Editar Venta</h3>
                    <p className="text-center text-muted">
                        ¡Bienvenido a nuestra plataforma de ventas! En esta sección, podrás explorar la lista completa de ventas asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de ventas.
                        <br />¡Que disfrutes explorando la lista de ventas!
                        <br />
                        <Link to={'/sales'}>
                            <Button variant="outline-secondary" size="sm"><i className="bi bi-list"></i> Listar ventas</Button>
                        </Link>{' '}
                        <Link to={'/sales/new/create'}>
                            <Button variant="success" size="sm"><i className="bi bi-plus"></i> Nueva venta</Button>
                        </Link>
                        <br />
                        <br />
                        <Button onClick={() => handleShow()} title="Acceder a los productos" className="btn-outline-success" size="lg" variant="light"><i className="bi bi-plus"></i> Productos</Button>
                    </p>
                </div>
                <Col xs={9}>
                    <Card>
                        <Card.Header className='bg-light h4' style={{ fontWeight: 'bold' }}>Detalles de la venta</Card.Header>
                        <Card.Body>
                            <Table striped size='sm' responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre</th>
                                        <th>Unidad</th>
                                        <th>Precio</th>
                                        <th>Total</th>
                                        <th>Cantidad</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {editSale.details?.map((detail, index) => {
                                        return (
                                            <tr key={detail.product?.id}>
                                                <td>{index + 1}.</td>
                                                <td>{detail?.product?.name}</td>
                                                <td>{detail.product?.unit?.name}</td>
                                                <td>{detail.product?.salePrice ? '$' + detail.product?.salePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} COP</td>
                                                <td>{detail.product?.salePrice ? '$' + (detail.product?.salePrice * parseInt(detail.quantity)).toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} COP</td>
                                                <td><Form.Control type='number' min='1' max={detail.product?.stock} className='mb-3' placeholder='Cant.' defaultValue={detail.quantity} onChange={(e) => addToCart(detail.product, e.target.value)} /></td>
                                                <td>
                                                    <Button title="Remover de la factura" onClick={() => removeFromCart(detail.product?.id)} className="btn-outline-danger" size="sm" variant="light"><i className="bi bi-trash"></i></Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={3}>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={updateSale}>
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="date" min={moment().tz(timeZone).format('YYYY-MM-DD')} value={moment(editSale.deliveryDate).tz(timeZone).format('YYYY-MM-DD')} onChange={(e) => setEditSale({ ...editSale, deliveryDate: moment(e.target.value).clone().tz(timeZone).valueOf() })} required />
                                <Form.Label>Total</Form.Label>
                                <Form.Control className="mb-3" size="sm" type="number" value={total} readOnly />
                                <Form.Select className="mb-3" size="sm" value={editSale?.statu?.id ? editSale?.statu?.id : ''} onChange={(e) => setEditSale({ ...editSale, statu: { id: e.target.value } })} required>
                                    <option value="">Selecciona un Estado</option>
                                    {statu.map((statu, _index) => {
                                        return (
                                            <option key={statu.id} value={statu.id}>{statu.name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Label>Observaciones</Form.Label>
                                <Form.Control className="mb-3" size="sm" as="textarea" defaultValue={editSale.observations} onChange={(e) => setEditSale({ ...editSale, observations: e.target.value })} />
                                <br />
                                <Button variant="success" size="sm" type="submit" disabled={updatingSale}>{updatingSale ? 'Guardando cambios...' : 'Guardar cambios'}</Button>{' '}
                                {/* <Button variant="secondary" size="sm" onClick={destroyCart}>Cancelar</Button>{' '} */}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ventana de productos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataTable
                        title={`Total: ${total.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} COP`}
                        columns={columns}
                        data={filteredItems}
                        //progressPending={!loaded}
                        pagination={true}
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                    />
                    {/* <Form.Label>Search:</Form.Label>
                    <Form.Control type='text' className='mb-3' placeholder='Search' onChange={(e) => getProductByName(e.target.value)} />
                    <Table striped size='sm' responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Unidad</th>
                                <th>Stock</th>
                                <th>Total</th>
                                <th>Estado</th>
                                <th>Cantidad</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product, index) => {
                                return (
                                    <tr key={product.id}>
                                        <td>{index + 1}.</td>
                                        <td>{product.name}</td>
                                        <td>{product.unit?.name}</td>
                                        <td>{product.stock}</td>
                                        <td>{product.salePrice ? '$' + product.salePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} COP</td>
                                        <td>{product.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</td>
                                        <td><Form.Control type='number' size="sm" min='1' max={product.stock} className='mb-3' defaultValue={0} placeholder='Cant.' onChange={(e) => setQuantity(e.target.value)} /></td>
                                        <td>
                                            <Button title="Agregar a la factura" onClick={() => addToCart(product, quantity)} className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-cart-plus"></i></Button>
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td colSpan={7} className="text-end">Total:</td>
                                <td>{total}</td>
                            </tr>
                        </tbody>
                    </Table> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" title="Salir de la lista de productos" size="sm" onClick={handleClose}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </Container>
    );
}

export default SalesEdit;
