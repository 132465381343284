import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Placeholder, Row } from 'react-bootstrap';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { apiGallery, apiRequest } from '../../../services/config';
import { StatusContext } from '../../../contexts/status-context';

const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
`;

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CUSTOMProducts = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);

    const [products, setProducts] = useState([]);
    const [loaded, setLoaded] = useState(true);

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = products.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
            || item.unit?.name && item.unit?.name.toLowerCase().includes(filterText.toLowerCase()),
    );

    const columns = [
        {
            name: 'Codigo',
            selector: row => row.code,
            sortable: true,
        },
        {
            name: 'Foto',
            selector: row => <><img src={`${apiGallery()}/images/${row?.business?.logo}`} alt={'logo'} style={{ width: '40px', height: '40px' }} /></>,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Unidad',
            selector: row => row.unit?.name,
            sortable: true,
        },
        {
            name: 'Proveedor',
            selector: row => row.supplier?.name,
            sortable: true,
        },
        {
            name: 'Precio Venta',
            selector: row => <>{row.salePrice.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>,
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => <>{row.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</>,
            sortable: true,
        },
    ];

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId) {
            getProducts();
        }
    }, [context, initialized, isPassed.businessId]);

    const getProducts = async () => {
        setLoaded(false);
        await axios.get(`${apiRequest()}/products/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setProducts(res.data);
            setLoaded(true);
        }).catch((err) => {
            setLoaded(true);
            toast.error({ render: "Error: No se pudo obtener los productos, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <TextField
                id="search"
                type="text"
                placeholder="Buscar por Nombre"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
                autoFocus={true}
            />
            <ClearButton type="button" onClick={onClear}>
                X
            </ClearButton>
        </>
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <Container className='mt-5'>
            <Row>
                <Col xs={12}>
                    <div className="page-title">
                        <h3 className='text-center'>Mis productos</h3>
                        <p className="text-center text-muted">
                            ¡Bienvenido a nuestra plataforma de productos! En esta sección, podrás explorar la lista completa de productos asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de productos.
                            <br />¡Que disfrutes explorando la lista de productos!
                            <br />
                        </p>
                    </div>
                    <Card>
                        <Card.Body>
                            {(products.length === 0 && !loaded) ? (
                                <>
                                    <Placeholder as={Row} size="xs" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} size="sm" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                    <Placeholder as={Row} size="lg" animation="glow">
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                        <Col xs={3}>
                                            <Placeholder className="w-100" />
                                        </Col>
                                    </Placeholder>
                                </>
                            ) :
                                <>
                                    <DataTable
                                        //title="Listado de productos"
                                        columns={columns}
                                        data={filteredItems}
                                        progressPending={!loaded}
                                        pagination={true}
                                        paginationResetDefaultPage={resetPaginationToggle}
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                        persistTableHead
                                    />
                                </>
                            }

                        </Card.Body>
                    </Card>
                    <ToastContainer />
                </Col>
            </Row>
        </Container>
    );
}

export default CUSTOMProducts;
