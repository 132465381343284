import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { StatusContext } from "../../contexts/status-context";
import { apiRequest } from "../../services/config";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment-timezone";

const SellerDashboard = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);
    const [eventSource, setEventSource] = useState(null);
    const [events, setEvents] = useState([]);
    const timeZone = moment.tz.guess();
    const [request, setRequest] = useState({
        start: moment().startOf('day').tz(timeZone).format('YYYY-MM-DD'),
        end: moment().add(1, 'days').startOf('day').tz(timeZone).format('YYYY-MM-DD')
    });
    const [dashboard, setDashboard] = useState({});

    /**
     * Monthly sales
     */
    const [monthlySales, setMonthlySales] = useState([]);
    const monthlySalesOptions = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Ventas Mensuales'
        },
        xAxis: {
            type: 'category',
            title: {
                text: 'Mes'
            }
        },
        yAxis: {
            title: {
                text: 'Ventas'
            }
        },
        series: [{
            name: 'Ventas Mensuales',
            data: monthlySales ? Object.keys(monthlySales).map(month => ({
                name: month,
                y: monthlySales[month]
            })) : []
        }]
    };
    const options = {

        title: {
            text: 'Historial de ventas',
            align: 'left'
        },
        credits: {
            enabled: false
        },

        // subtitle: {
        //     text: 'By Job Category. Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>.',
        //     align: 'left'
        // },

        yAxis: {
            title: {
                text: 'Ventas individuales'
            }
        },

        xAxis: {
            /*type: 'datetime',
            title: {
                text: 'Fecha'
            },
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%b', this.value);
                }
            },
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S.%L',
                second: '%H:%M:%S',
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%e %b %Y',
                week: '%e %b %Y',
                month: '%b %Y',
                year: '%YYYY'
            },*/
            /*accessibility: {
                rangeDescription: `Range: ${moment().format('YYYY')} to ${moment().add(1, 'year').format('YYYY')}`
            },*/
            //categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
        },

        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },

        series: [{
            name: 'Venta',
            data: [],
            color: '#2fb074',
        },
        ],

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }

    };

    const salesSellers = {
        chart: {
            type: 'line'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Ventas por tiendas',
            align: 'left'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.1f}</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [
            {
                name: 'Ventas',
                colorByPoint: true,
                data: [{
                    name: 'Chrome',
                    y: 70.67,
                    sliced: true,
                    selected: true
                }, {
                    name: 'Edge',
                    y: 1.77
                }, {
                    name: 'Firefox',
                    y: 4.86
                }, {
                    name: 'Safari',
                    y: 2.63
                }, {
                    name: 'Internet Explorer',
                    y: 1.53
                }, {
                    name: 'Opera',
                    y: 1.96
                }, {
                    name: 'Sogou Explorer',
                    y: 2.84
                }, {
                    name: 'QQ',
                    y: 0.51
                }, {
                    name: 'Other',
                    y: 2.6
                }]
            }
        ]
    };

    const salesRoutes = {
        chart: {
            type: 'pie'
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Ventas por rutas',
            align: 'left'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>${point.y:.1f} ({point.percentage:.1f}%)</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [
            {
                name: 'Ruta',
                colorByPoint: true,
                data: []
            },
        ]
    };

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        getSales();
        if (request.start !== '' && request.end !== '' && isPassed.businessId !== '' && isPassed.others?.sellerId) {
            getDashboard();
        }
    }, [initialized, isPassed.businessId, request.start, request.end, isPassed.others?.sellerId]);

    const getSales = async () => {
        await axios.get(`${apiRequest()}/sales/business/${isPassed.businessId}/sellers/${isPassed.others?.sellerId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            res.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            setEvents(res.data);
            setMonthlySales(calculateMonthlySales(res.data));
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener las ventas, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const calculateMonthlySales = (salesToMonths = []) => {
        const monthlySalesData = {};

        salesToMonths.forEach(sale => {
            const date = moment(sale.createdAt);
            const monthYear = `${date.format('YYYY')} - ${parseInt(date.format('MM'))}`;

            if (!monthlySalesData[monthYear]) {
                monthlySalesData[monthYear] = {
                    totalSales: 0,
                    salesCount: 0
                    // Puedes agregar más propiedades según tus necesidades
                };
            }

            monthlySalesData[monthYear].totalSales += sale.total;
            monthlySalesData[monthYear].salesCount += 1;
            // Puedes agregar más cálculos aquí, como el promedio de ventas por mes
        });
        return monthlySalesData;
    };

    const getDashboard = async () => {
        await axios.post(`${apiRequest()}/dashboards/business/${isPassed.businessId}/${request.start}/${request.end}/seller/${isPassed.others?.sellerId}`, {}, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setDashboard(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los datos del dashboard, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    return (
        <Container fluid>
            <Row className="mb-2">
                <Col md={4}>

                </Col>
                <Col md={4}>
                    <Row>
                        <Col md={6}>
                            <Form.Control type="date" max={moment().format('YYYY-MM-DD')} onChange={(e) => setRequest({ ...request, start: moment(e.target.value).format('YYYY-MM-DD') })} defaultValue={request.start} />
                        </Col>
                        <Col md={6}>
                            <Form.Control type="date" max={moment().format('YYYY-MM-DD')} onChange={(e) => setRequest({ ...request, end: moment(e.target.value).format('YYYY-MM-DD') })} value={request.end} />
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>

                </Col>
            </Row>
            <Row md={12}>
                <Col xs={12} md={4}>
                    <Card className="mb-2 shadow">
                        <Card.Body>
                            <HighchartsReact
                                highcharts={Highcharts}
                                //constructorType={'line'}
                                options={{ ...options, series: [{ name: 'Ventas', color: '#2fb074', data: events.filter(item => item.total !== undefined).map(item => [moment(item.createdAt).tz(timeZone).format('YYYY-MM-DD'), item.total]) }] }}
                            />
                        </Card.Body>
                    </Card>
                    <Card className="mb-2 shadow">
                        <Card.Body>
                            <HighchartsReact
                                highcharts={Highcharts}
                                //constructorType={'column'}
                                options={{ ...monthlySalesOptions, series: [{ name: 'Ventas', color: '#2fb074', data: Object.keys(monthlySales).map(key => [key, monthlySales[key].totalSales]) }] }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col key={2} md={8}>
                    <Row className="mb-2">
                        <Col md={3}>
                            <Card className="shadow mb-2">
                                <Card.Body>
                                    <Row>
                                        <Col xs={6}>
                                            <i className="bi bi-cart3" style={{ fontSize: '3rem', color: '#044426' }}></i>
                                        </Col>
                                        <Col xs={6}>
                                            <Card.Title className="text-secondary">Facturado</Card.Title>
                                            <Card.Text className="h5">
                                                ${dashboard?.salesToday ? dashboard?.salesToday?.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="shadow mb-2">
                                <Card.Body>
                                    <Row>
                                        <Col xs={6}>
                                            <i className="bi bi-sign-intersection-y-fill text-info" style={{ fontSize: '3rem' }}></i>
                                        </Col>
                                        <Col xs={6}>
                                            <Card.Title className="text-secondary">Rutas</Card.Title>
                                            <Card.Text className="h5">
                                            {dashboard?.routesCount}
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="shadow mb-2">
                                <Card.Body>
                                    <Row>
                                        <Col xs={6}>
                                            <i className="bi bi-person-vcard-fill" style={{ fontSize: '3rem', color: '#056D44' }}></i>
                                        </Col>
                                        <Col xs={6}>
                                            <Card.Title className="text-secondary">Clientes</Card.Title>
                                            <Card.Text className="h5">
                                                {dashboard?.customersCount}
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="shadow mb-2">
                                <Card.Body>
                                    <Row>
                                        <Col xs={6}>
                                            <i className="bi bi-cart3" style={{ fontSize: '3rem', color: '#040A05' }}></i>
                                        </Col>
                                        <Col xs={6} className="text-end">
                                            <Card.Title className="text-secondary">Productos</Card.Title>
                                            <Card.Text className="h5">
                                                {dashboard?.productsCount}
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Card className="shadow mb-2">
                                <Card.Body>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        //constructorType={'line'}
                                        options={{...salesRoutes, series: [{ name: 'Ruta', color: '#2fb074', data: dashboard.salesByRoutes?.filter(item => item.name !== undefined).map(item => [item.name, item.y]) }] }}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="shadow mb-2">
                                <Card.Body>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        //constructorType={'pie'}                                        
                                        options={{...salesSellers, series: [{ name: 'Venta', color: '#2fb074', data: dashboard.salesBySellers?.filter(item => item.name !== undefined).map(item => [item.name, item.y]) }] }}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Row xs={1} md={2}>
                <Col key={1}>
                    <Card>
                        <Card.Body>
                            <HighchartsReact
                                highcharts={Highcharts}
                                //constructorType={'line'}
                                options={{ ...options, series: [{ name: 'Ventas', color: '#2fb074', data: events.filter(item => item.total !== undefined).map(item => [moment(item.createdAt).tz(timeZone).format('YYYY-MM-DD'), item.total]) }] }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col key={2}>
                    <Card>
                        <Card.Body>
                            <HighchartsReact
                                highcharts={Highcharts}
                                //constructorType={'column'}
                                options={{ ...monthlySalesOptions, series: [{ name: 'Ventas', color: '#2fb074', data: Object.keys(monthlySales).map(key => [key, monthlySales[key].totalSales]) }] }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row> */}
        </Container>
    );
}
export default SellerDashboard;