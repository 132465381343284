import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Placeholder, Row, Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { apiRequest } from '../../services/config';
import { StatusContext } from '../../contexts/status-context';
import moment from 'moment-timezone';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFSuppliers from '../reports/consolidations/suppliers';

const CSuppliers = () => {

    const [context, setContext] = useContext(StatusContext);
    const { keycloak, initialized } = useKeycloak();
    const [suppliers, setSuppliers] = useState([]);
    const [consolidate, setConsolidate] = useState([]);
    const [supplier, setSupplier] = useState({});
    const [loaded, setLoaded] = useState(true);
    let totalCompra = 0;
    let totalVenta = 0;
    const [request, setRequest] = useState({
        supplierId: '',
        date: moment().format('YYYY-MM-DD')
    });

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId) {
            getSuppliers();
        }
    }, [context, initialized, isPassed.businessId]);

    useEffect(() => {
        getConsolidationSupplier();
        getSupplier();
    }, [request.supplierId, request.date]);

    const getSuppliers = async () => {
        await axios.get(`${apiRequest()}/suppliers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            const serverSuppliers = res.data;
            const transformed = serverSuppliers.map((supplier) => ({
                value: supplier.id,
                label: supplier.name,
                key: supplier.id
            }));
            const withDefault = [
                { value: '', label: 'Selecciona un proveedor', key: 0 },
                ...transformed
            ];
            setSuppliers(withDefault);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener los proveedores, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getSupplier = async () => {
        await axios.get(`${apiRequest()}/suppliers/${request.supplierId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setSupplier(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener el proveedor, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    const getConsolidationSupplier = async () => {
        if (request.supplierId !== '' && request.date !== '') {
            setLoaded(false);
            setConsolidate([]);
            await axios.get(`${apiRequest()}/consolidations/suppliers/business/${isPassed.businessId}/${request.supplierId}/${request.date}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
                setConsolidate(res.data);
                setLoaded(true);
            }).catch((_err) => {
                setLoaded(true);
                toast.error({ render: "Error: No se pudo obtener las consolidaciones, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            });
        }
    }

    const sendEmail = async () => {
        const toastId = toast.loading("Enviando correo...", { hideProgressBar: false, position: "bottom-center" });
        await axios.post(`${apiRequest()}/consolidations/suppliers/business/${isPassed.businessId}/${request.supplierId}/${request.date}`, {}, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setSupplier(res.data);
            toast.update(toastId, { render: "Envio de correo reservado exitosamente", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo enviar el correo, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    return (
        <>
            <Container>
                <h1 className="text-center">Página de Consolidado de proveedores</h1>
                <Row className="mb-3">
                    <Col>
                        <Form.Label>Proveedor:</Form.Label>
                        <Select defaultValue={suppliers[0]} onChange={(e) => { setRequest({ ...request, supplierId: e.value }); }} classNamePrefix={'select'} options={suppliers} className="mb-3" isSearchable={true} isClearable={false} required />
                    </Col>
                    <Col>
                        <Form.Label>Fecha:</Form.Label>
                        <Form.Control type='date' max={moment().format('YYYY-MM-DD')} defaultValue={request.date} onChange={(e) => { setRequest({ ...request, date: moment(e.target.value).format('YYYY-MM-DD') }); }} className="mb-3" required />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className='text-center'>
                        <Button size="sm" type='button' variant='primary' onClick={sendEmail} disabled={consolidate.length === 0}><i className="bi bi-envelope-at"></i> Enviar email a proveedor</Button>{' '}
                        <PDFDownloadLink document={<PDFSuppliers data={consolidate} supplier={supplier} businessId={isPassed.businessId} />} fileName="consolidado_proveedor.pdf">
                            {({ blob, url, loading, error }) =>
                                <Button size="sm" type='button' variant='danger' onClick={() => { }}><i className="bi bi-download"></i> {loading ? 'Cargando documento...' : 'Descargar'}</Button>
                            }
                        </PDFDownloadLink>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(consolidate.length === 0 && !loaded) ? (
                            <>
                                <Placeholder as={Row} size="xs" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} size="sm" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                                <Placeholder as={Row} size="lg" animation="glow">
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                    <Col xs={3}>
                                        <Placeholder className="w-100" />
                                    </Col>
                                </Placeholder>
                            </>
                        ) : (
                            <>
                                <Table size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th className="text-success">Descripción</th>
                                            <th className="text-success">Cantidad</th>
                                            <th className="text-success">P. Compra</th>
                                            <th className="text-success">P. Venta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {consolidate.map((cons, _index) => {
                                            totalCompra += cons.TotalCompra;
                                            totalVenta += cons.TotalVenta;
                                            return (
                                                <tr key={cons.id}>
                                                    <td>{cons.name}</td>
                                                    <td>{cons.quantity}</td>
                                                    <td>{cons.TotalCompra.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>{cons.TotalVenta.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan={2}></td>
                                            <td className='text-success'>{totalCompra.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{totalVenta.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </>
                        )}

                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )

}

export default CSuppliers;