import { useContext, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';

const Assigned = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);

    const [assignments, setAssignments] = useState([]);

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId && isPassed.others?.sellerId != '') {
            getAssignments();
        }
    }, [context, initialized, isPassed.businessId, isPassed.others?.sellerId]);

    const getAssignments = async () => {
        await axios.get(`${apiRequest()}/assignments/business/${isPassed.businessId}/${isPassed.others?.sellerId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setAssignments(res.data);
        }).catch((_err) => {
            toast.error({ render: "Error: No se pudo obtener las asignaciones, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    return (
        <Container className='mt-5'>
            <Row>
                <Col xs={12}>
                    <div className="page-title">
                        <h3 className='text-center'>Mis rutas Asignadas</h3>
                        <p className="text-center text-muted">
                            Nos enorgullece presentar las rutas de venta asignadas a nuestros dedicados y talentosos vendedores. Con el objetivo de brindar un servicio excepcional y llegar a nuestros clientes de manera efectiva, hemos diseñado cuidadosamente estas rutas para garantizar una cobertura óptima y una atención personalizada en cada área.
                            <br />¡Que disfrutes explorando la lista de asignación de rutas!
                            <br />
                        </p>
                    </div>
                    <Card>
                        <Card.Body>
                            <Table responsive size='sm' style={{ fontSize: '30px' }}>
                                <thead>
                                    <tr>
                                        <th>Dia</th>
                                        <th>Ruta</th>
                                        <th>Foraneo</th>
                                        <th>N. clientes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assignments.map((assignment, index) => {
                                        return (
                                            <tr key={assignment.id}>
                                                <td>{assignment.dayName}.</td>
                                                <td>{assignment.routeName}</td>
                                                <td><b>{assignment.foreigner ? <span className="text-success">Si</span> : <span className="text-danger">Si</span>}</b></td>
                                                <td>{assignment.customersCount}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
}

export default Assigned;
