import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Placeholder, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { apiRequest } from '../../../services/config';
import { StatusContext } from '../../../contexts/status-context';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';

const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
`;

const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CUSTOMCustomers = () => {

  const { keycloak, initialized } = useKeycloak();
  const [context, setContext] = useContext(StatusContext);

  const [routes, setRoutes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const [createCustomer, setCreateCustomer] = useState({});
  const [create, setCreate] = useState(false);
  const handleCloseCreate = () => setCreate(false);
  const handleShowCreate = () => setCreate(true);
  const [creating, setCreating] = useState(false);

  const [updateCustomer, setUpdateCustomer] = useState({});
  const [update, setUpdate] = useState(false);
  const handleCloseUpdate = () => setUpdate(false);
  const handleShowUpdate = () => setUpdate(true);
  const [updating, setUpdating] = useState(false);

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = customers.filter(
    item => item.corporateName && item.corporateName.toLowerCase().includes(filterText.toLowerCase()) 
    || item.code && item.code.toLowerCase().includes(filterText.toLowerCase())
    || item.owner && item.owner.toLowerCase().includes(filterText.toLowerCase())
    || item.route?.name && item.route?.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const columns = [
    {
      name: 'Codigo',
      selector: row => row.code,
      sortable: true,
    },
    {
      name: 'Ruta',
      selector: row => row.route?.name,
      sortable: true,
    },
    {
      name: 'Razon Social',
      selector: row => row.corporateName,
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: row => row.owner,
      sortable: true,
    },
    {
      name: 'Dirección',
      selector: row => row.address,
      sortable: true,
    },
    {
      name: 'Correo',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Telefono',
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: 'Estado',
      selector: row => <>{row.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</>,
      sortable: true,
    },
    {
      name: 'Acciones',
      selector: row => <>{/*<Button title="Ver detalles" className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>{' '}*/}
        <Button onClick={() => getCustomer(row.id)} title="Editar datos de cliente" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button>{' '}
        <Link to={`/sales/${row.id}/create`}><Button title="Ir para facturar" className="btn-outline-primary" size="sm" variant="light"><i className="bi bi-cart3"></i></Button></Link></>,
      sortable: false,
    },
  ];

  const isPassed = useMemo(() => {
    return context;
  }, [context]);

  useEffect(() => {
    if (initialized && isPassed.businessId) {
      getCustomers();
      getRoutes();
    }
  }, [context, initialized, isPassed.businessId]);

  const getCustomers = async () => {
    setLoaded(false);
    await axios.get(`${apiRequest()}/customers/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setCustomers(res.data);
      setLoaded(true);
    }).catch((err) => {
      setLoaded(true);
      toast.error({ render: "Error: No se pudo obtener los clientes, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const getRoutes = async () => {
    await axios.get(`${apiRequest()}/routes/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setRoutes(res.data);
    }).catch((_err) => {
      toast.error({ render: "Error: No se pudo obtener las rutas, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const getCustomer = async (id) => {
    handleShowUpdate();
    setUpdateCustomer({});
    setUpdating(true);
    await axios.get(`${apiRequest()}/customers/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setUpdateCustomer(res.data);
      setUpdating(false);
    }).catch((_err) => {
      setUpdating(false);
      toast.error({ render: "Error: No se pudo obtener el cliente, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    setUpdating(true);
    const toastId = toast.loading("Guardando cambios, por favor espere...", { hideProgressBar: false, position: "bottom-center" });
    await axios.put(`${apiRequest()}/customers/${updateCustomer.id}`, updateCustomer, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setUpdateCustomer({});
        handleCloseUpdate();
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getCustomers();
        setUpdating(false);
      }
    }).catch((_err) => {
      setUpdating(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveCustomer = async (event) => {
    event.preventDefault();
    setCreating(true);
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    await axios.post(`${apiRequest()}/customers/route/${createCustomer?.route?.id}`, createCustomer, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setCreateCustomer({});
        event.target.reset();
        handleCloseCreate();
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getCustomers();
        setCreating(false);
      }
    }).catch((_err) => {
      setCreating(false);
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const getCustomerByName = async (filter) => {
    await axios.get(`${apiRequest()}/customers/business/${isPassed.businessId}/${filter}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setFilteredCustomers(res.data);
      setCustomers(res.data);
    }).catch((err) => {
      toast.error({ render: "Error: No se pudo obtener los clientes, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField
        id="search"
        type="text"
        placeholder="Buscar por Nombre"
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        autoFocus={true}
      />
      <ClearButton type="button" onClick={onClear}>
        X
      </ClearButton>
    </>
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Container className='mt-5'>
      <Row>
        <Col xs={12}>
          <div className="page-title">
            <h3 className='text-center'>Mis clientes</h3>
            <p className="text-center text-muted">
              ¡Bienvenido a nuestra plataforma de clientes! En esta sección, podrás explorar la lista completa de clientes asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de clientes.
              <br />¡Que disfrutes explorando la lista de clientes!
              <br />
              <Button variant="success" size="sm" onClick={handleShowCreate}>Nuevo cliente</Button>
            </p>
          </div>
          <Card>
            <Card.Body>
              {(customers.length === 0 && !loaded) ? (
                <>
                  <Placeholder as={Row} size="xs" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} size="sm" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} size="lg" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                </>
              ) : (
                <>
                  <DataTable
                    //title="Listado de productos"
                    columns={columns}
                    data={filteredItems}
                    progressPending={!loaded}
                    pagination={true}
                    paginationResetDefaultPage={resetPaginationToggle}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                </>
              )}
            </Card.Body>

          </Card>
          <Offcanvas placement="end" show={create} onHide={handleCloseCreate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Nuevo cliente</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              ¡Bienvenido al proceso de registro de clientes!
              <br />
              El registro de clientes es el primer paso para aprovechar al máximo todas las funcionalidades que ofrecemos. Por favor, completa el formulario a continuación con la información necesaria para crear tu cuenta y comenzar a gestionar tus clientes de manera eficiente. Recuerda proporcionar datos precisos y actualizados para garantizar una experiencia óptima. Una vez que completes el registro, podrás acceder a tu cuenta y empezar a explorar todas las características diseñadas para simplificar la gestión de clientes.
              <br />
              <br />
              <Form onSubmit={saveCustomer}>
                <Form.Label>Ruta</Form.Label>
                <Form.Select className="mb-3" defaultValue={createCustomer?.route?.id} onChange={(e) => setCreateCustomer({ ...createCustomer, route: { id: e.target.value } })} required>
                  <option value="">Selecciona una ruta</option>
                  {routes.map((route, _index) => {
                    return (
                      <option key={route.id} value={route.id}>{route.name}</option>
                    )
                  })}
                </Form.Select>
                <Form.Label>Razon social</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createCustomer.corporateName} onChange={(e) => setCreateCustomer({ ...createCustomer, corporateName: e.target.value })} required />
                <Form.Label>Representante</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createCustomer.owner} onChange={(e) => setCreateCustomer({ ...createCustomer, owner: e.target.value })} required />
                <Form.Label>Correo</Form.Label>
                <Form.Control className="mb-3" size="sm" type="email" defaultValue={createCustomer.email} onChange={(e) => setCreateCustomer({ ...createCustomer, email: e.target.value })} required />
                <Form.Label>Telefono</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createCustomer.phone} onChange={(e) => setCreateCustomer({ ...createCustomer, phone: e.target.value })} required />
                <Form.Label>Direccíon</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createCustomer.address} onChange={(e) => setCreateCustomer({ ...createCustomer, address: e.target.value })} required />
                <Form.Label>Ciudad</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createCustomer.city} onChange={(e) => setCreateCustomer({ ...createCustomer, city: e.target.value })} required />
                <Form.Label>Estado</Form.Label>
                <Form.Switch checked={createCustomer.status || false} value={createCustomer.status} onChange={(e) => setCreateCustomer({ ...createCustomer, status: e.target.checked })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={creating}>{creating ? 'Guardando...' : 'Guardar'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseCreate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas placement="end" show={update} onHide={handleCloseUpdate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Actualizar cliente</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              En este espacio, podrás realizar cambios y actualizaciones en la información de tus clientes de manera rápida y sencilla. Mantener la información de tus clientes al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
              <br />
              <br />
              <Form onSubmit={saveChanges}>
                <Form.Label>Ruta</Form.Label>
                <Form.Select className="mb-3" value={updateCustomer.route?.id || ''} onChange={(e) => setUpdateCustomer({ ...updateCustomer, route: { id: e.target.value } })} required>
                  {routes.map((route, _index) => {
                    return (
                      <option key={route.id} value={route.id}>{route.name}</option>
                    )
                  })}
                </Form.Select>
                <Form.Label>Code</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateCustomer.code} disabled />
                <Form.Label>Razon social</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateCustomer.corporateName} onChange={(e) => setUpdateCustomer({ ...updateCustomer, corporateName: e.target.value })} required />
                <Form.Label>Representante</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateCustomer.owner} onChange={(e) => setUpdateCustomer({ ...updateCustomer, owner: e.target.value })} required />
                <Form.Label>Correo</Form.Label>
                <Form.Control className="mb-3" size="sm" type="email" defaultValue={updateCustomer.email} onChange={(e) => setUpdateCustomer({ ...updateCustomer, email: e.target.value })} required />
                <Form.Label>Telefono</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateCustomer.phone} onChange={(e) => setUpdateCustomer({ ...updateCustomer, phone: e.target.value })} required />
                <Form.Label>Direccíon</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateCustomer.address} onChange={(e) => setUpdateCustomer({ ...updateCustomer, address: e.target.value })} required />
                <Form.Label>Ciudad</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateCustomer.city} onChange={(e) => setUpdateCustomer({ ...updateCustomer, city: e.target.value })} required />
                <Form.Label>Estado</Form.Label>
                <Form.Switch checked={updateCustomer.status || false} value={updateCustomer.status} onChange={(e) => setUpdateCustomer({ ...updateCustomer, status: e.target.checked })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={updating}>{updating ? 'Guardando cambios...' : 'Guardar cambios'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseUpdate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}

export default CUSTOMCustomers;
