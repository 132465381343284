import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';

const Routes = () => {

  const { keycloak, initialized } = useKeycloak();
  const [context, setContext] = useContext(StatusContext);

  const [routes, setRoutes] = useState([]);

  const [createRoute, setCreateRoute] = useState({});
  const [create, setCreate] = useState(false);
  const handleCloseCreate = () => setCreate(false);
  const handleShowCreate = () => setCreate(true);
  const [creating, setCreating] = useState(false);

  const [updateRoute, setUpdateRoute] = useState({});
  const [update, setUpdate] = useState(false);
  const handleCloseUpdate = () => setUpdate(false);
  const handleShowUpdate = () => setUpdate(true);
  const [updating, setUpdating] = useState(false);

  const isPassed = useMemo(() => {
    return context;
  }, [context]);

  useEffect(() => {
    if (initialized && isPassed.businessId) {
      getRoutes();
    }
  }, [context, initialized, isPassed.businessId]);

  const getRoutes = async () => {
    await axios.get(`${apiRequest()}/routes/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setRoutes(res.data);
    }).catch((_err) => {
      toast.error({ render: "Error: No se pudo obtener las rutas, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const getRoute = async (id) => {
    handleShowUpdate();
    setUpdateRoute({});
    await axios.get(`${apiRequest()}/routes/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      setUpdateRoute(res.data);
    }).catch((_err) => {
      toast.error({ render: "Error: No se pudo obtener la ruta, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    });
  }

  const saveChanges = async (event) => {
    event.preventDefault();
    setUpdating(true);
    const toastId = toast.loading("Guardando cambios, por favor espere...", { hideProgressBar: false, position: "bottom-center" });
    await axios.put(`${apiRequest()}/routes/${updateRoute.id}`, updateRoute, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setUpdateRoute({});
        event.target.reset();
        handleCloseUpdate();
        setUpdating(false);
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getRoutes();
      }
    }).catch((_err) => {
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      setUpdating(false);
    });
  }

  const saveRoute = async (event) => {
    event.preventDefault();
    setCreating(true);
    const toastId = toast.loading("Guardando...", { hideProgressBar: false, position: "bottom-center" });
    await axios.post(`${apiRequest()}/routes/business/${isPassed.businessId}`, createRoute, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      if (res.status === 200) {
        setCreateRoute({});
        handleCloseCreate();
        event.target.reset();
        toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        getRoutes();
        setCreating(false);
      }
    }).catch((err) => {
      setCreating(false);
      console.error('error', err);
    });
  }

  return (
    <Container className='mt-5'>
      <Row>
        <Col xs={12}>
          <div className="page-title">
            <h3 className='text-center'>Mis rutas</h3>
            <p className="text-center text-muted">
              ¡Bienvenido a nuestra plataforma de rutas! En esta sección, podrás explorar la lista completa de rutas asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de rutas.
              <br />¡Que disfrutes explorando la lista de rutas!
              <br />
              <Button variant="success" size="sm" onClick={handleShowCreate}>Nueva ruta</Button>
            </p>
          </div>
          <Card>
            <Card.Body>
              <Table striped size='sm' responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Foranea</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {routes.map((route, index, array) => {
                    return (
                      <tr key={route.id}>
                        <td>{index + 1}.</td>
                        <td>{route.name}</td>
                        <td>{route.foreigner ? 'Si' : 'No'}</td>
                        <td>{route.status ? <i className="text-success bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i> : <i className="text-danger bi bi-info-circle-fill" style={{ fontSize: '20px' }}></i>}</td>
                        <td>
                          <Button title="Ver detalles" className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>
                          {' '}
                          <Button onClick={() => getRoute(route.id)} title="Editar ruta" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Offcanvas placement="end" show={create} onHide={handleCloseCreate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Nueva venta</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            ¡Bienvenido al proceso de registro de rutas!
            <br />
            El registro de rutas es el primer paso para aprovechar al máximo todas las funcionalidades que ofrecemos. Por favor, completa el formulario a continuación con la información necesaria para crear tu cuenta y comenzar a gestionar tus rutas de manera eficiente. Recuerda proporcionar datos precisos y actualizados para garantizar una experiencia óptima. Una vez que completes el registro, podrás acceder a tu cuenta y empezar a explorar todas las características diseñadas para simplificar la gestión de rutas.
              <br />
              <br />
              <Form onSubmit={saveRoute}>
                <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={createRoute.name} onChange={(e) => setCreateRoute({ ...createRoute, name: e.target.value })} required />
                <Form.Label>Foranea</Form.Label>
                <Form.Switch defaultChecked={createRoute.foreigner} onChange={(e) => setCreateRoute({ ...createRoute, foreigner: e.target.checked })} />
                <Form.Label>Estado</Form.Label>
                <Form.Switch defaultChecked={createRoute.status} onChange={(e) => setCreateRoute({ ...createRoute, status: e.target.checked })} />
                <br />
                <Button variant="success" type="submit" size="sm" disabled={creating}>{creating ? 'Guardando...' : 'Guardar'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseCreate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          <Offcanvas placement="end" show={update} onHide={handleCloseUpdate} backdrop="static">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Actualizar ruta</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              En este espacio, podrás realizar cambios y actualizaciones en la información de tus rutas de manera rápida y sencilla. Mantener la información de tus rutas al día es crucial para brindar un servicio excepcional, y aquí te proporcionamos las herramientas necesarias para lograrlo.
              <br />
              <br />
              <Form onSubmit={saveChanges}>
              <Form.Label>Nombre</Form.Label>
                <Form.Control className="mb-3" size="sm" type="text" defaultValue={updateRoute.name} onChange={(e) => setUpdateRoute({ ...updateRoute, name: e.target.value })} required />
                <Form.Label>Foranea</Form.Label>
                <Form.Switch checked={updateRoute?.foreigner || false} value={updateRoute?.foreigner} onChange={(e) => setUpdateRoute({ ...updateRoute, foreigner: e.target.checked })} />
                <Form.Label>Estado</Form.Label>
                <Form.Switch checked={updateRoute?.status || false} value={updateRoute?.status} onChange={(e) => setUpdateRoute({ ...updateRoute, status: e.target.checked })} />
                <br />
                <Button variant="success" size="sm" type="submit" disabled={updating}>{updating ? 'Guardando cambios...' : 'Guardar'}</Button>{' '}
                <Button variant="secondary" size="sm" onClick={handleCloseUpdate}>Cancelar</Button>{' '}
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
          
        </Col>
      </Row>
      <ToastContainer/>
    </Container>
  );
}

export default Routes;
