import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment-timezone';
import { apiGallery, apiRequest } from '../../../services/config';
import axios from 'axios';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 40,
    },
    header: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    logoContainer: {
        width: '12%',
        textAlign: 'left',
    },
    logo: {
        width: 85,
    },
    companyNameContainer: {
        fontSize: '9px',
        width: '75%',
        textAlign: 'center',
    },
    companyName: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    companyDetails: {
        fontSize: 11,
        marginTop: 5,
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        borderTop: '1 solid #C1CED9',
        padding: 8,
        color: '#5D6975',
        fontSize: 10,
    },
    thead: {
        fontWeight: 'bold',
        backgroundColor: 'orange',
        fontSize: 9,
        paddingTop: 4,
        paddingLeft: 7,
        flex: 1,
        borderColor: 'whitesmoke',
        borderRightWidth: 1,
        borderBottomWidth: 1
    },
    tbody: {
        fontSize: 9,
        paddingTop: 4,
        paddingLeft: 7,
        flex: 1,
        borderColor: 'whitesmoke',
        borderRightWidth: 1,
        borderBottomWidth: 1
    },
});

const PDFSuppliers = ({ data, supplier, businessId }) => {

    const [consolidate, setConsolidate] = useState([]);
    const [business, setBusiness] = useState({});

    let totalCompra = 0;
    let totalVenta = 0;

    const timeZone = moment.tz.guess();

    useEffect(() => {
        if (data && businessId) {
            getBusiness();
            setConsolidate(data);
        }
    }, [data, businessId]);

    const getBusiness = () => {
        axios.get(`${apiRequest()}/business/${businessId}`, { /*headers: { Authorization: `Bearer ${keycloak.token}` },*/ withCredentials: true }).then((res) => {
            setBusiness(res.data);
        }).catch((err) => {
            console.error('error', err);
        });
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Encabezado */}
                <View style={styles.header}>
                    <View style={styles.logoContainer}>
                        <Image src={`${apiGallery()}/images/${business?.logo ? business?.logo : 'logoNegrdo.png'}`} style={styles.logo} />
                    </View>
                    <View style={styles.companyNameContainer}>
                        <Text style={styles.companyName}>{business?.name}</Text>
                        <Text style={styles.companyDetails}>
                            CONSOLIDADO DE PROVEEDOR {"\n"}
                            {supplier?.name}
                        </Text>
                    </View>
                </View>

                {/* Cuerpo del documento */}
                <View>
                    <View>
                        <View>
                            <View style={{ width: '100%', flexDirection: 'row' }}>
                                <View style={styles.thead}>
                                    <Text style={{ fontWeight: 'bold' }}>Descripción</Text>
                                </View>
                                <View style={styles.thead}>
                                    <Text>Cantidad</Text>
                                </View>
                                <View style={styles.thead}>
                                    <Text style={{ textAlign: 'center' }}>P. Compra</Text>
                                </View>
                                <View style={styles.thead}>
                                    <Text style={{ textAlign: 'center' }}>P. Venta</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    {/* Detalles de la venta */}
                    <View style={{ fontSize: '15px' }}>
                        {consolidate.map((cons) => {
                            totalCompra += cons.TotalCompra;
                            totalVenta += cons.TotalVenta;
                        })}
                        {consolidate.map((cons) => (
                            <View key={cons.id}>
                                <View style={{ width: '100%', flexDirection: 'row' }}>
                                    <View style={[styles.tbody]}>
                                        <Text >{cons?.name}</Text>
                                    </View>
                                    <View style={styles.tbody}>
                                        <Text>{cons?.quantity} </Text>
                                    </View>
                                    <View style={styles.tbody}>
                                        <Text style={{ textAlign: 'center' }}>{cons?.TotalCompra.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                    </View>
                                    <View style={styles.tbody}>
                                        <Text style={{ textAlign: 'center' }}>{cons?.TotalVenta.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                                    </View>
                                </View>
                            </View>
                        ))}
                    </View>
                    <View>
                        <View style={{ width: '100%', flexDirection: 'row' }}>
                            <View style={{ width: '50%', textAlign: 'center', fontSize: '9px', marginTop: '2px' }}>
                                <Text style={{ fontWeight: 'bold', fontSize: '9px' }}></Text>
                            </View>
                            <View style={{ width: '50%', textAlign: 'center', fontSize: '9px', marginTop: '2px' }}>
                                <Text style={{ fontWeight: 'bold', fontSize: '9px' }}></Text>
                            </View>
                            <View style={{ width: '50%', textAlign: 'center', fontSize: '9px', marginTop: '2px' }}>
                                <Text style={{ fontWeight: 'bold', fontSize: '9px' }}>T: {totalCompra.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                            </View>
                            <View style={{ width: '50%', textAlign: 'center', fontSize: '9px', marginTop: '2px' }}>
                                <Text style={{ fontWeight: 'bold', fontSize: '9px' }}>T: {totalVenta.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                {/* Pie de página */}
                <View style={styles.footer}>
                    <Text>Negrdo © {new Date().getFullYear()} Todos los derechos reservados.</Text>
                    <Text style={{ textAlign: 'center', fontSize: '9px' }} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
            </Page>
        </Document>
    );
}
export default PDFSuppliers;