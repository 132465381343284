import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Offcanvas, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import { ToastContainer, toast } from 'react-toastify';

const Account = () => {

    const { keycloak, initialized } = useKeycloak();
    const [context, setContext] = useContext(StatusContext);

    const [updateAccount, setUpdateAccount] = useState({
        nit: '',
        name: '',
        logo: '',
        postalCode: '',
        address: '',
        email: '',
        phone: '',
        subscription: '',
        status: ''
    });
    const [imagePreview, setImagePreview] = useState('');
    const [updating, setUpdating] = useState(false);

    const isPassed = useMemo(() => {
        return context;
    }, [context]);

    useEffect(() => {
        if (initialized && isPassed.businessId) {
            getAccount();
        }
    }, [context, initialized, isPassed.businessId]);

    const getAccount = async () => {
        await axios.get(`${apiRequest()}/business/${isPassed.businessId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            setUpdateAccount(res.data);
        }).catch((err) => {
            console.error('error', err);
        });
    }

    const saveChanges = async (event) => {
        event.preventDefault();
        setUpdating(true);
        const toastId = toast.loading("Guardando cambios, por favor espere...", { hideProgressBar: false, position: "bottom-center" });
        await axios.put(`${apiRequest()}/business/${updateAccount.id}`, updateAccount, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
            if (res.status === 200) {
                setUpdateAccount({});
                toast.update(toastId, { render: "Operación realizada con exito", hideProgressBar: true, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                setUpdating(false);
                getAccount();
            }
        }).catch((err) => {
            console.log(err);
            setUpdating(false);
            toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        });
    }

    // Manejar el cambio de archivos
    const handleImageChange = (event) => {
        const reader = new FileReader();
        const file = event.target.files[0];

        reader.onloadend = (event) => {
            setImagePreview(reader.result);
            setUpdateAccount({ ...updateAccount, logo: event.target.result });
        }

        reader.readAsDataURL(file);
    };

    return (
        <Container className='mt-5'>
            <Row>
                <Col xs={12}>
                    <div className="page-title">
                        <h3 className='text-center'>Cuenta y negocio</h3>
                        <p className="text-center text-muted">
                            ¡Bienvenido a nuestra plataforma de configuración de información de tu cuenta!
                            <br />¡Aqui puedes gestionar tus datos de la cuenta!
                            <br />
                        </p>
                    </div>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={saveChanges}>
                                <Form.Label>Nit</Form.Label>
                                <Form.Control value={updateAccount.nit} onChange={(e) => setUpdateAccount({ ...updateAccount, nit: e.target.value })} className="mb-3" readOnly required />
                                <Form.Label>Razon Social</Form.Label>
                                <Form.Control value={updateAccount.name} onChange={(e) => setUpdateAccount({ ...updateAccount, name: e.target.value })} className="mb-3" readOnly required />
                                <Form.Label>Correo</Form.Label>
                                <Form.Control value={updateAccount.email} onChange={(e) => setUpdateAccount({ ...updateAccount, email: e.target.value })} className="mb-3" required />
                                <Form.Label>Dirección</Form.Label>
                                <Form.Control value={updateAccount.address} onChange={(e) => setUpdateAccount({ ...updateAccount, address: e.target.value })} className="mb-3" required />
                                <Form.Label>Codigo postal</Form.Label>
                                <Form.Control value={updateAccount.postalCode} onChange={(e) => setUpdateAccount({ ...updateAccount, postalCode: e.target.value })} className="mb-3" required />
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control value={updateAccount.phone} onChange={(e) => setUpdateAccount({ ...updateAccount, phone: e.target.value })} className="mb-3" required />
                                <Form.Label>Subscription</Form.Label>
                                <Form.Control value={updateAccount.subscription} onChange={(e) => setUpdateAccount({ ...updateAccount, subscription: e.target.value })} className="mb-3" readOnly required />
                                <Form.Label>Logo</Form.Label>
                                <Form.Control type="file" accept="image/*" onChange={handleImageChange} className="mb-3" />
                                <Button variant="success" size="sm" type="submit" disabled={updating}>{updating ? 'Guardando cambios...' : 'Guardar cambios'}</Button>{' '}
                            </Form>
                            <br />
                            <br />
                            {imagePreview && (
                                <img src={imagePreview} alt="Vista previa de la imagen" style={{ maxWidth: '300px', maxHeight: '300px' }} />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
}

export default Account;
