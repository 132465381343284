import { useContext, useEffect, useMemo, useState } from 'react';
import { Form, Badge, Button, Card, Col, Container, Modal, Placeholder, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import { apiEvents, apiRequest } from '../../services/config';
import { useKeycloak } from '@react-keycloak/web';
import { StatusContext } from '../../contexts/status-context';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Sales = () => {

  const { keycloak, initialized } = useKeycloak();
  const [context, setContext] = useContext(StatusContext);
  const [eventSource, setEventSource] = useState(null);

  const [sales, setSales] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  }
  const [deleteId, setDeleteId] = useState('');
  const [deleting, setDeleting] = useState(false);

  const timeZone = moment.tz.guess();
  const [request, setRequest] = useState({
    dateStart: moment().tz(timeZone).format('YYYY-MM-DD'),
    dateEnd: moment().tz(timeZone).format('YYYY-MM-DD')
  });

  const isPassed = useMemo(() => {
    return context;
  }, [context]);

  useEffect(() => {
    if (initialized && isPassed.businessId !== '') {
      console.log('Running...');
      const newEvent = new EventSource(`${apiEvents()}/events/distributions/sales/${isPassed.businessId}`, { withCredentials: true });

      setEventSource(newEvent);

      newEvent.onopen = (event) => {
        console.log(event.isTrusted);
      }
      newEvent.onerror = () => {
        console.log('SERVER EVENT ERROR');
      }
      newEvent.onmessage = (message) => {
        if (message.isTrusted) {
          const event = JSON.parse(message.data);
          setSales(prev => [event, ...prev]);
          console.log('Ready');
        } else {
          console.log('NOT TRUSTED');
        }
      }
      return () => newEvent.close();
    }
  }, [context, initialized, setEventSource]);

  useEffect(() => {
    if (initialized && isPassed.businessId) {
      getSales();
    }
  }, [context, initialized, isPassed.businessId, request.dateStart, request.dateEnd]);

  const getSales = async () => {
    if (request.dateStart !== '' && request.dateEnd !== '') {
      setLoaded(false);
      await axios.get(`${apiRequest()}/sales/business/${isPassed.businessId}/${request.dateStart}/${request.dateEnd}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
        setSales(res.data);
        setLoaded(true);
      }).catch((_err) => {
        setLoaded(true);
        toast.error({ render: "Error: No se pudo obtener las ventas, intenta de nuevo", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      });
    }
  }

  const deleteSale = async () => {
    const toastId = toast.loading("Eliminando, por favor espere...", { hideProgressBar: false, position: "bottom-center" });
    setDeleting(true);
    await axios.delete(`${apiRequest()}/sales/${deleteId}`, { headers: { Authorization: `Bearer ${keycloak.token}` }, withCredentials: true }).then((res) => {
      toast.update(toastId, { render: "Operación realizada con exito", type: "success", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
      getSales();
      handleClose();
      setDeleting(false);
    }).catch((_err) => {
      toast.update(toastId, { render: "Error: por favor revisa que los datos sean correctos e intenta de nuevo", type: "error", hideProgressBar: true, isLoading: false, autoClose: 5000, closeOnClick: true });
      setDeleting(false);
    });
  }

  return (
    <Container className='mt-5'>
      <Row>
        <Col xs={12}>
          <div className="page-title">
            <h3 className='text-center'>Mis ventas</h3>
            <p className="text-center text-muted">
              ¡Bienvenido a nuestra plataforma de ventas! En esta sección, podrás explorar la lista completa de ventas asociados a tu negocio. Ya sea para gestionar sus detalles, realizar un seguimiento de sus actividades o actualizar su información, aquí encontrarás todas las herramientas que necesitas para mantener una visión integral de tu base de ventas.
              <br />¡Que disfrutes explorando la lista de ventas!
              <br />
              <br />
              <Link to={'/sales/new/create'}>
                <Button variant="success" size="sm"><i className="bi bi-plus"></i> Nueva venta</Button>
              </Link>
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mb-0">
        <Col>
          <Form.Label>Desde:</Form.Label>
          <Form.Control size="sm" type='date' max={moment().tz(timeZone).format('YYYY-MM-DD')} defaultValue={request.dateStart} onChange={(e) => { setRequest({ ...request, dateStart: moment(e.target.value).tz(timeZone).format('YYYY-MM-DD') }); }} className="mb-3" required />
        </Col>
        <Col>
          <Form.Label>Hasta:</Form.Label>
          <Form.Control size="sm" type='date' max={moment().tz(timeZone).format('YYYY-MM-DD')} defaultValue={request.dateEnd} onChange={(e) => { setRequest({ ...request, dateEnd: moment(e.target.value).tz(timeZone).format('YYYY-MM-DD') }); }} className="mb-3" required />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              {(sales.length === 0 && !loaded) ? (
                <>
                  <Placeholder as={Row} size="xs" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} size="sm" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                  <Placeholder as={Row} size="lg" animation="glow">
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                    <Col xs={3}>
                      <Placeholder className="w-100" />
                    </Col>
                  </Placeholder>
                </>
              ) : (
                <>
                  <Table striped size="sm" responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Cliente</th>
                        <th>Ruta</th>
                        <th>Vendedor</th>
                        <th>Total</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>Creado</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sales.map((sale, index) => {
                        return (
                          <tr key={sale.id}>
                            <td>{index + 1}.</td>
                            <td>{sale.customer?.corporateName}</td>
                            <td>{sale.customer?.route?.name}</td>
                            <td>{sale.seller?.people?.name}</td>
                            <td>{sale.total ? '$' + sale.total.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '$0.00'} COP</td>
                            <td>{moment(sale.deliveryDate).format('DD-MM-YYYY')}</td>
                            <td>{sale.statu.name === 'PENDIENTE' ? <Badge bg="danger">{sale.statu?.name}</Badge> : '<Badge bg="success">{sale.statu.name}</Badge>'}</td>
                            <td>{moment(sale.createdAt).fromNow()}</td>
                            <td>
                              <Button title="Ver detalles" onClick={() => window.open(`/reports/invoice/${sale.id}`, "_blank")} className="btn-outline-success" size="sm" variant="light"><i className="bi bi-eye"></i></Button>
                              {' '}
                              <Link to={`/sales/${sale.id}/edit`}>
                                <Button title="Editar venta" className="btn-outline-secondary" size="sm" variant="light"><i className="bi bi-pencil-square"></i></Button>
                              </Link>
                              {' '}
                              <Button onClick={() => handleShow(sale.id)} title="Eliminar venta" className="btn-outline-danger" size="sm" variant="light"><i className="bi bi-trash"></i></Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ventana de Dialogo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que deseas eliminar esta venta? Esta acción no se puede deshacer y se perderán todos los detalles asociados a esta transacción.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deleteSale} size="sm" disabled={deleting}>{deleting ? 'Eliminando...' : 'Eliminar'}</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Container>
  );
}

export default Sales;
